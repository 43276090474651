import { Component, AfterViewChecked, OnInit, ElementRef } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenService } from './token.service';
import { StorageServiceKeys } from './Model/StorageServiceKeys';
@Component({
  selector: 'app-root',
  templateUrl: './servicenow.component.html',
  styleUrls: ['./servicenow.component.css']
})
export class ServicenowComponent implements AfterViewChecked, OnInit {
  height: number;
  width: number;
  initialized: boolean = false;
  el: ElementRef;
  constructor(
    el: ElementRef,
    private storageService: StorageService,
  ) {
    this.height = 500;
    this.el = el;
  }

  async ngOnInit() {
    localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
    localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
    localStorage.removeItem(StorageServiceKeys.snAuthWindowOpened);

      // On startup initiallizes the observable
      //TODO: It is not performing the authorization
      this.storageService.updateTokenObservable();
  }
  ngAfterViewChecked(): void {
    this.setSoftphoneSize();
  }
  private setSoftphoneSize(): void {
    const newHeight = this.getHeight();
    if (this.initialized && newHeight < 10) {
      return;
    }

    //TODO: Look at this code and understand how it works
    if (newHeight !== this.height) {
      this.initialized = true;
      this.height = newHeight;
      api.setAppHeight(this.height);
    }
  }

  /**
   *
   * @returns the height of the app
   */
  private getHeight(): number {
    return this.el.nativeElement.children[0].scrollHeight + 2;
  }
}
