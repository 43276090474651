/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import * as $ from 'jquery';
import { LOG_LEVEL } from './LogLevel';
import { LogObj } from './LogObj';
import { ERROR_CODE } from './ErrorCode';
import { LOG_SOURCE } from './LogSource';
import { AbstractLogger } from './AbstractLogger';
import { ILoggerConfiguration } from '../models/LoggerConfiguration';

export * from './LogLevel';
export * from './LogObj';
export * from './ErrorCode';
export * from './LogSource';

/**
 * Logger for AMC's DaVinci Apps. Logs are sent to cloud.
 * Currently there is no way for 3rd party apps to make use of this.
 */
export class DaVinciLogger extends AbstractLogger {
  private loopBuffer: LogObj[] = [];
  private traceBuffer: LogObj[] = [];
  private debugBuffer: LogObj[] = [];
  private informationBuffer: LogObj[] = [];
  private warningBuffer: LogObj[] = [];
  private errorBuffer: LogObj[] = [];
  private criticalBuffer: LogObj[] = [];

  constructor(logSource: LOG_SOURCE | string, isDev: boolean = false, apiUrl: string = null) {
    super(logSource, isDev, apiUrl);
  }

  private getLogBufferReference(logLevel: LOG_LEVEL): LogObj[] {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return this.loopBuffer;
      case LOG_LEVEL.Trace:
        return this.traceBuffer;
      case LOG_LEVEL.Debug:
        return this.debugBuffer;
      case LOG_LEVEL.Information:
        return this.informationBuffer;
      case LOG_LEVEL.Warning:
        return this.warningBuffer;
      case LOG_LEVEL.Error:
        return this.errorBuffer;
      case LOG_LEVEL.Critical:
        return this.criticalBuffer;
      default:
        return [];
    }
  }

  setConfiguration(config: ILoggerConfiguration): void {
    this.config = config;
  }

  private printConsoleLog(logObj: LogObj): void {
    if (this._isDev) {
      console.log(JSON.stringify(logObj, null, '\t'));
    }
  }

  private logMessage(methodLogLevel: LOG_LEVEL, message: string, errorCode: ERROR_CODE, localTime: Date): void {
    const logObj: LogObj = this.generateLogObj(methodLogLevel, errorCode, message, localTime);

    if (this.logLevel <= methodLogLevel) {
      if (this._isDev) {
        this.printConsoleLog(logObj);
      } else {
        this.getLogBufferReference(methodLogLevel).push(logObj);
      }
    }
  }

  logLoop(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Loop, message, errorCode, localTime);
  }

  logTrace(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Trace, message, errorCode, localTime);
  }

  logDebug(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Debug, message, errorCode, localTime);
  }

  logInformation(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Information, message, errorCode, localTime);
  }

  logWarning(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Warning, message, errorCode, localTime);
  }

  logError(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Error, message, errorCode, localTime);
  }

  logCritical(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    this.logMessage(LOG_LEVEL.Critical, message, errorCode, localTime);
  }

  public pushLogs(): void {
    if (!this.apiUrl) {
      return;
    }
    this.postBuffers(LOG_LEVEL.Loop);
    this.postBuffers(LOG_LEVEL.Trace);
    this.postBuffers(LOG_LEVEL.Debug);
    this.postBuffers(LOG_LEVEL.Information);
    this.postBuffers(LOG_LEVEL.Warning);
    this.postBuffers(LOG_LEVEL.Error);
    this.postBuffers(LOG_LEVEL.Critical);
  }

  private postBuffers(logLevel: LOG_LEVEL): void {
    const loggerEndpoint: string = this.getLoggerEndpoint(logLevel);
    const logBuffer: LogObj[] = this.getLogBufferReference(logLevel);
    if (logBuffer.length > 0) {
      $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: loggerEndpoint,
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: JSON.stringify(logBuffer)
      });
      logBuffer.splice(0, logBuffer.length);
    }
  }

  public async pushLogsAsync(): Promise<void> {
    if (!this.apiUrl) {
      return;
    }
    await this.postBuffersAsync(LOG_LEVEL.Loop);
    await this.postBuffersAsync(LOG_LEVEL.Trace);
    await this.postBuffersAsync(LOG_LEVEL.Debug);
    await this.postBuffersAsync(LOG_LEVEL.Information);
    await this.postBuffersAsync(LOG_LEVEL.Warning);
    await this.postBuffersAsync(LOG_LEVEL.Error);
    await this.postBuffersAsync(LOG_LEVEL.Critical);
  }

  private async postBuffersAsync(logLevel: LOG_LEVEL): Promise<void> {
    const loggerEndpoint: string = this.getLoggerEndpoint(logLevel);
    const logBuffer: LogObj[] = this.getLogBufferReference(logLevel);
    if (logBuffer.length > 0) {
      await $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: loggerEndpoint,
        async: false,
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: JSON.stringify(logBuffer)
      });
      logBuffer.splice(0, logBuffer.length);
    }
  }

  private getLoggerEndpoint(logLevel: LOG_LEVEL): string {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogLoop/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Trace:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Debug:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogDebug/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Information:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogInformation/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Warning:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogWarning/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Error:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogError/${this._logSource}/${this._logLevel}`;
      case LOG_LEVEL.Critical:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogCritical/${this._logSource}/${this._logLevel}`;
      default:
        return `${AbstractLogger.ApiServiceUrl}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}`;
    }
  }
}
