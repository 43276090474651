/* eslint-disable max-len */
/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { IContextualContact, CONTEXTUAL_OPERATION_TYPE } from '../models/ContextualOperation';
import { CHANNEL_TYPES } from '../models/ChannelTypes';

/**
 * This registers the app to handle event if the agent starts an interaction with the contextual controls.
 * The agent can either choose a contact added by [[addContextualContacts]] or
 * type something in(e.g. a phone number) which will be in the displayName of the [[IContextualContact]].
 *
 * ![alt text](media://contextual_contacts.png)
 *
 * - See [[addContextualContacts]]
 * - See [[clearContextualContacts]]
 * - See [[contextualOperation]]
 * @param callback The event handler. This should start an interaction with the contact provided. The interaction should correspond to the channel type if provided
 */
export function registerContextualControls(callback: (contact: IContextualContact, channelType?: CHANNEL_TYPES) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.CONTEXTUAL_EVENT);
}

/**
 * Apps can use this to provide a list of contacts to the agent.
 *
 * The contacts are only displayed to the agent when your app is in the "Center(tabs)" position and is the selected tab.
 *
 * ![alt text](media://contextual_contacts.png)
 *
 * - See [[registerContextualControls]]
 * - See [[clearContextualContacts]]
 * - See [[contextualOperation]]
 * @param contacts List of contacts your app provides
 */
export function addContextualContacts(contacts: IContextualContact[]): Promise<void> {
  return sendRequestToFramework(OPERATIONS.ADD_CONTEXTUAL_ACCESS_LIST, [contacts]).then((val) => {}); // make sure we return void
}

/**
 * This removes any contacts added by [[addContextualContacts]]
 *
 * - See [[addContextualContacts]]
 * - See [[registerContextualControls]]
 * - See [[contextualOperation]]
 */
export function clearContextualContacts(): Promise<void> {
  return sendRequestToFramework(OPERATIONS.CLEAR_CONTEXTUAL_ACCESS_LIST).then(
    () => {} // make sure we return void
  );
}

/**
 * Call this start an operation on an interaction(ex: transfer, conference, etc..).
 * The agent will then be able to choose who to complete the operation with
 * by either choosing an [[IContextualContact]] or typing something in.
 *
 * - See [[addContextualContacts]]
 * - See [[clearContextualContacts]]
 * - See [[registerContextualControls]]
 * @param operationType The type of operation
 *
 * @returns The [[IContextualContact]] the agent chooses.
 *
 * If the agent types something in instead of choosing an existing contact
 * then what they type will be in the displayName field of the [[IContextualContact]].
 *
 * If the agent cancels the operation then the promise will reject.
 */
export function contextualOperation(operationType: CONTEXTUAL_OPERATION_TYPE, channelType?: CHANNEL_TYPES, callback?: Function): Promise<IContextualContact> {
  return sendRequestToFramework(OPERATIONS.CONTEXTUAL_OPERATION, [operationType], callback);
}
