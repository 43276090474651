import { Pipe, PipeTransform } from '@angular/core';
import { IScenarioDetails } from './Model/IScenarioDetails';

@Pipe({
  name: 'scenarioFilter'
})

export class ScenarioFilter implements PipeTransform {

  transform(items: any[], filterText: string): any[] {
    if (!items || items.length === 0 || !filterText) {
      return items;
    }

    return items.filter(item => item.value.channelName === filterText);
  }

}
