/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { IAppConfiguration } from '../AmcApi';
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * This is needed to get the global configuration for the DaVinci Framework.
 *
 * @returns The configuration of the global framework config for the current DaVinci profile.
 */
export function getDaVinciAgentConfig(): Promise<IAppConfiguration> {
  return sendRequestToFramework(OPERATIONS.GET_DAVINCI_AGENT_CONFIG).then((resolved) => resolved);
}
