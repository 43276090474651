import * as api from '@amc-technology/davinci-api';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { StorageServiceKeys } from './Model/StorageServiceKeys';
import { ISNInteraction } from './Model/ServiceNowModel';
import { IActivity } from './Model/IActivity';
import { LoggerService } from './logger.service';
import { IOAuthTokenData } from './Model/IOauth2Model';

@Injectable()
export class StorageService {
  private _fileName = "storage.service.ts";

  private _currentInteraction: api.IInteraction;
  private _currentSnInteraction: ISNInteraction;
  private currentActivity: IActivity;
  public callNotes: string;
  public currentScenarioId: string;
  public activeScenarioIdList: string[];
  public activityList: {
    [scenarioId: string]
    : IActivity;
  };

  private _appConfig: any;

  constructor(private tokenService: TokenService, private loggerService: LoggerService) {
    this._currentInteraction = null;
    this._currentInteraction = null;
    window.addEventListener('storage', this.storageListener.bind(this));
    if (!localStorage.getItem(StorageServiceKeys.snAccessToken)) {
      window.localStorage.setItem(
        StorageServiceKeys.snAccessToken,
        window.location.hash
      );
    }
    this.currentScenarioId = null;
    this.activeScenarioIdList = [];
    this.callNotes = '';
    this.activityList = {};

    this._appConfig = undefined;
  }

  async getAppConfig(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this._appConfig) {
          this._appConfig = await api.getConfig();
        }
        resolve(this._appConfig);
      } catch (error) {
        reject(error);
      }
    })
  }


  /**
   * Sets the currentSnInteraction and updates the localStorage with this values
   * @param {ISNInteraction} snInteraction The ServiceNowInteraction to be saved
  */
  set serviceNowInteraction(snInteraction: ISNInteraction) {
    this._currentSnInteraction = snInteraction;
    localStorage.setItem(
      StorageServiceKeys.snInteraction,
      JSON.stringify(snInteraction)
    );
  }

  /**
   * returns the currentSnInteraction either from the variable or retreiving it from storageService
   * @return {ISNInteraction} 
  */
  get serviceNowInteraction(): ISNInteraction {
    const fnName = 'serviceNowInteraction';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this._currentSnInteraction) {
        return this._currentSnInteraction;
      }
      this._currentSnInteraction = JSON.parse(
        localStorage.getItem(StorageServiceKeys.snInteraction)
      ) as ISNInteraction;
      return this._currentSnInteraction;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Sets currentScenarioID and calls another function which Stores 
   * currentInteraction, callNotes, activityList, currentScenarioId and activeScenarioIdList to localstorage
   * @param currentScenarioId
   */
  public setCurrentScenarioId(currentScenarioId: string) {
    const fnName = 'setCurrentScenarioId';
    try {
      this.currentScenarioId = currentScenarioId;
      this.storeCurrentInteractionToLocalStorage();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Listens to token updates into localStorage
   * @param event 
   */
  private storageListener(event: StorageEvent) {
    if (event.key === StorageServiceKeys.snAccessToken) {
      this.tokenService.setAccessToken(event.newValue);
    } else if (event.key === StorageServiceKeys.snAuthorizationCode) {
      this.tokenService.getNewAccessTokenWithAuthCode();
    }
  }

  updateTokenObservable() {
    this.tokenService.setAccessToken(this.getAccessToken());
  }

  public getCurrentInteraction(): api.IInteraction {
    return this._currentInteraction;
  }

  //TODO2: Don't we need to update the localStorage as well?

  /**
   * Sets the currentActivity variable
   * @param activity 
   */
  public setCurrentActivity(activity: IActivity) {
    this.currentActivity = activity;
  }

  /**
   * Returns the activity corresponding to the ScenarioID. If scenarioID is null returns the currentActivity.
   * @param scenarioId 
   * @returns The value of 
   */
  public getActivity(scenarioId: string = null): IActivity {
    const fnName = 'getActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (!scenarioId) {
        return this.currentActivity;
      }
      if (this.activityList[scenarioId]) {
        return this.activityList[scenarioId];
      }
      return null;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public appendDescription(value: string) {
    const fnName = 'appendDescription';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.getActivity().Description += (value);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  /**
   * Sets the description for the corresponding scenarioId and calls storeCurrentInteractionToLocalStorage
   * @param description 
   * @param scenarioId 
   */
  public getDescription() {
    //TODO3: If scenarioId is not found, there is an error which should be thrown!
    const fnName = 'getDescription';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      return this.getActivity()?.Description;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public setDescription(description: string, scenarioId: string) {
    const fnName = 'setDescription';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.activityList[scenarioId]) {
        this.activityList[scenarioId].Description = description;
      }
      this.storeCurrentInteractionToLocalStorage();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Sets the current interaction and updates the localStorage by caling storeCurrentInteractionToLocalStorage
   * @param currentInteraction 
   */
  public setCurrentInteraction(currentInteraction: api.IInteraction) {
    this._currentInteraction = currentInteraction;
    this.storeCurrentInteractionToLocalStorage();
  }

  //TODO: what if we have multiple interactions? Is it OK to set currentInteraction to null
  //TODO: In case of multiple interaction why do we set the active scenario to index 0?

  /**
   * Nullifies serviceNowInteraction and currentInteraction, Sets callNotes to '' and and sets currentScenarioId to either activeScenarioIdList[0] or null.
   * @param scenarioId 
   */
  public onInteractionDisconnect(scenarioId: string) {
    const fnName = 'onInteractionDisconnect';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      this.setCurrentInteraction(null);
      this.serviceNowInteraction = null;
      this.callNotes = '';
      if (this.currentScenarioId === scenarioId) {
        if (this.activeScenarioIdList.length > 0) {
          this.setCurrentScenarioId(this.activeScenarioIdList[0]);
        } else {
          this.setCurrentScenarioId(null);
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Stores currentInteraction, callNotes, activityList, currentScenarioId and activeScenarioIdList to localstorage
   */
  public storeCurrentInteractionToLocalStorage() {
    const fnName = 'storeCurrentInteractionToLocalStorage';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      localStorage.setItem(
        StorageServiceKeys.interaction,
        JSON.stringify({
          currentInteraction: this._currentInteraction,
          callNotes: this.getActivity()?.Description || '',
          activityList: this.activityList,
          currentScenarioId: this.currentScenarioId,
          activeScenarioIdList: this.activeScenarioIdList
        })
      );
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Reads the localStorage and Updates the currentInteraction, callNotes, activityList, currentScenarioId and activeScenarioIdList
   */
  public syncWithLocalStorage() {
    const fnName = 'syncWithLocalStorage';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      const browserStorage = JSON.parse(
        localStorage.getItem(StorageServiceKeys.interaction)
      );
      if (browserStorage) {
        this._currentInteraction = browserStorage.currentInteraction;
        this.callNotes = browserStorage.callNotes;
        this.activityList = browserStorage.activityList;
        this.currentScenarioId = browserStorage.currentScenarioId;
        this.activeScenarioIdList = browserStorage.activeScenarioIdList;
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName)
    }
  }

  public storeAuthorizationCode(tokenData: IOAuthTokenData) {
    localStorage.setItem(StorageServiceKeys.snAuthorizationCode, tokenData.code);
    localStorage.removeItem(StorageServiceKeys.snAuthWindowOpened);
  }


  /**
   * Retrieves the accessToken from localStorage and returns it.
   * @returns the accessToken
   */
  public getAccessToken() {
    return localStorage.getItem(StorageServiceKeys.snAccessToken);
  }

  /**
   * Adds the activity to acitivityList and add its scenarioId to the list of activeScenarioIds, finally updates localStorage.
   * @param activity 
   */
  public addActivity(activity: IActivity) {
    const fnName = 'addActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.activityList[activity.ScenarioId] = activity;
      this.activeScenarioIdList.push(activity.ScenarioId);
      this.storeCurrentInteractionToLocalStorage();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public updateTotalInteractionTime(scenarioId: string) {
    const fnName = 'updateTotalInteractionTime';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;

        // Close any interacions that are not disconnected
        for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
          this.updateInteractionDurationActivity(scenarioId, i, true);
        }

        if (this.activityList[scenarioId].DurationOnInteractions) {
          this.activityList[scenarioId].CallDurationInSeconds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].DurationOnInteractions)) {
            if (this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration) {
              this.activityList[scenarioId].CallDurationInSeconds += this.activityList[scenarioId].DurationOnInteractions[i].interactionDuration;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeCurrentInteractionToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public updateInteractionDurationActivity(scenarioId: string, interactionId: string, isDisconnected: boolean) {
    const fnName = 'updateInteractionDurationActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;
        if (!this.activityList[scenarioId].DurationOnInteractions ||
          !this.activityList[scenarioId].DurationOnInteractions[interactionId]) {
          if (!isDisconnected) {
            // If the interaction is Not Disconnected for the first time
            this.activityList[scenarioId].DurationOnInteractions[interactionId] = {
              interactionDuration: 0,
              DurationTracking: []
            };

            this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('ServiceNow - Storage : updateInteractionDurationActivity First time for Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId);
          } // else ignore since the call connected didn't come yet or this must be an extra disconnect
        }
        else { // Interaction was strted earlier
          const trackingLength = this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking.length;
          if (isDisconnected && trackingLength > 0) {
            if (!this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start) {
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from localstorage then, convert it back to Date
                this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start =
                  new Date(this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start);
              }

              if (!this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration) {
                this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration = 0;
              }

              this.activityList[scenarioId].DurationOnInteractions[interactionId].interactionDuration +=
                Math.round((this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start.getTime()) / 1000);

              activityModified = true;
              this.loggerService.logger.logDebug('ServiceNow - Storage : updateInteractionDurationActivity call Disconnected for Scenario ID : '
                + scenarioId + ' InteractionId : ' + interactionId);
            }
          }

          if (!isDisconnected) {
            if (this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength - 1].start) {

              this.activityList[scenarioId].DurationOnInteractions[interactionId].DurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug('ServiceNow - Storage : updateInteractionDurationActivity call started Scenario ID : '
                + scenarioId + ' InteractionId : ' + interactionId);
            }
          }
        }

        if (activityModified) {
          this.storeCurrentInteractionToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public updateTotalHoldTime(scenarioId: string) {
    const fnName = 'updateTotalHoldTime';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;

        // Close any Hold interacions
        for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
          this.updateHoldInteractionActivityField(scenarioId, i, false);
        }

        if (this.activityList[scenarioId].HoldDurationOnInteractions) {
          this.activityList[scenarioId].HoldDurationInSeconds = 0;
          this.activityList[scenarioId].NumberOfHolds = 0;
          for (const i of Object.keys(this.activityList[scenarioId].HoldDurationOnInteractions)) {
            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration) {
              this.activityList[scenarioId].HoldDurationInSeconds += this.activityList[scenarioId].HoldDurationOnInteractions[i].holdDuration;
              activityModified = true;
            }

            if (this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds) {
              this.activityList[scenarioId].NumberOfHolds += this.activityList[scenarioId].HoldDurationOnInteractions[i].numberOfHolds;
              activityModified = true;
            }
          }
        }

        if (activityModified) {
          this.storeCurrentInteractionToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public updateHoldInteractionActivityField(scenarioId: string, interactionId: string, isHold: boolean) {
    const fnName = 'updateHoldInteractionActivityField';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      if (this.activityList[scenarioId]) {
        this.setActivityField(scenarioId, 'LastUpdated', new Date());
        let activityModified = false;
        if (!this.activityList[scenarioId].HoldDurationOnInteractions ||
          !this.activityList[scenarioId].HoldDurationOnInteractions[interactionId]) {
          if (isHold) {
            // If the interaction is on hold for the first time
            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId] = {
              numberOfHolds: 0,
              holdDuration: 0,
              holdDurationTracking: []
            };

            this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[0] = {
              start: new Date(),
              end: undefined
            };

            activityModified = true;
            this.loggerService.logger.logDebug('ServiceNow - Storage : updateHoldInteractionActivityField call OnHold First time for Scenario ID : '
              + scenarioId + ' InteractionId : ' + interactionId + ' isHold ' + isHold);
          } // else ignore since the call never went to hold
        }
        else { // Interaction was atleast once on OnHold before
          const trackingLength = this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking.length;
          if (!isHold && trackingLength > 0) {
            if (!this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start) {
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end = new Date();
              if (typeof this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start === 'string') {
                // If Start Date got loaded from localstorage then, convert it back to Date
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start =
                  new Date(this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start);
              }

              if (!this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration) {
                this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration = 0;
              }

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration +=
                Math.round((this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end.getTime() -
                  this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start.getTime()) / 1000);

              // this.activityList[scenarioId].HoldDurationInSeconds = this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDuration;

              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].numberOfHolds++;
              activityModified = true;
              this.loggerService.logger.logDebug('ServiceNow - Storage : updateHoldInteractionActivityField call Not OnHold for Scenario ID : '
                + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold);
            }
          }

          if (isHold) {
            if (this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].end &&
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength - 1].start) {
              // Make sure we take into account we handle multiple HOLD events when call is OnHOLD but we only track once
              // Once when the last HOLD / Retrieve are complete we take on next HOLD event as next OnHOLD occurance
              this.activityList[scenarioId].HoldDurationOnInteractions[interactionId].holdDurationTracking[trackingLength] = {
                start: new Date(),
                end: undefined
              };

              activityModified = true;
              this.loggerService.logger.logDebug('ServiceNow - Storage : updateHoldInteractionActivityField call OnHold for Scenario ID : '
                + scenarioId + ' InteractionId : ' + interactionId + ' Number of Holds  ' + trackingLength + ' isHold ' + isHold);
            }
          }
        }

        if (activityModified) {
          this.storeCurrentInteractionToLocalStorage();
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  public recentActivityListContains(scenarioId: string): boolean {
    const fnName = 'recentActivityListContains';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      return (this.activityList[scenarioId] && !this.activityList[scenarioId].IsActive);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Updates/adds the activity's field with the activityValue if the ScearioId is found
   * @param scenarioId 
   * @param activityField 
   * @param activityValue 
   */
  public setActivityField(scenarioId: string, activityField: string, activityValue: any) {
    const fnName = 'setActivityField';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.activityList[scenarioId]) {
        if (activityField !== 'LastUpdated') {
          this.activityList[scenarioId]['LastUpdated'] = new Date();
        }

        this.activityList[scenarioId][activityField] = activityValue;
      }
      this.storeCurrentInteractionToLocalStorage();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }
}
