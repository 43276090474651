import { IUserDetails } from './UserDetails';

/**
 * Contains information regarding raw transcript data for a single message.
 *
 */
export interface ITranscript {
  id: string;
  data: string;
  isComplete: boolean;
  context: IUserDetails;
  timestamp?: Date;
}

/**
 * Contains information regarding a completed transcript and all of its messages.
 *
 */
export interface ICompletedTranscript {
  id: string;
  messages: ITranscript[];
  analytics: IAnalytics[];
  startTimestamp?: Date;
  endTimestamp?: Date;
}

/**
 * Contains information regarding analytics generated from raw transcript data.
 *
 */
export interface IAnalytics {
  transcript: ITranscript;
  analytics: {
    type: ANALYTICS_TYPES;
    data: any;
  }[];
  timestamp?: Date;
}

/**
 * The type of analytics.
 */
export enum ANALYTICS_TYPES {
  PII,
  Sentiment,
  VirtualAgent
}
