/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { SearchRecords } from '../models/SearchRecords';

/**
 * Apps that integrate with a CRM should use this to allow other apps to get any CRM entities on the page the agent is actively viewing.
 *
 * See [[getPageInfo]]
 * @param callback This function should return(via a promise) any records/entities that are currently displayed on the CRM.
 */
export function registerGetPageInfo(callback: () => Promise<SearchRecords>) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.GET_PAGE_INFO);
}

/**
 * Gets any records or entities on the page the agent is currently viewing.
 *
 * See [[registerGetPageInfo]]
 *
 * Alternatively use [[registerOnFocus]] to get the records every time the page changes.
 * @returns Records on the currents page
 */
export function getPageInfo(): Promise<SearchRecords> {
  return sendRequestToFramework(OPERATIONS.GET_PAGE_INFO).then((val: any) => SearchRecords.fromJSON(val));
}

/**
 * An app that integrates with a CRM should call this when the user navigates to a new page, record, or entity.
 *
 * See [[registerOnFocus]]
 * @param records Any records or entities that are visible on the new page.
 */
export function onFocus(records: SearchRecords): Promise<void> {
  return sendRequestToFramework(OPERATIONS.ON_FOCUS, [records]).then((val) => {}); // make sure we return void
}

/**
 * Apps should use this if they want to know what page, record, or entity the agent navigates too.
 *
 * See [[onFocus]]
 *
 * Alternatively use [[getPageInfo]] to get the records on the current page.
 * @param callback Called when the user navigates to a new page, record, or entity.
 * 'records' contains any records on the new page.
 */
export function registerOnFocus(callback: (records: SearchRecords) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.ON_FOCUS);
}
