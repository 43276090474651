export enum StorageServiceKeys {
  interaction = 'interactionStorageKey',
  snAccessToken = 'snAccessTokenStorageKey',
  snRefreshToken = 'snRefreshTokenStorageKey',
  snAuthorizationCode = 'snAuthorizationCode',
  snInteraction = 'snInteractionStorageKey',
  snAccessTokenExpiration = 'snAccessTokenExpirationKey',
  snAuthWindowOpened = 'snAuthWindowOpened',
  snAuthRequestIsSentAlready = 'snAuthRequestIsSentAlready',
  snRefreshRequestIsSentAlready = 'snRefreshRequestIsSentAlready'
}
