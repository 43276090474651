import { Component, OnInit } from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { Application } from '@amc-technology/applicationangularframework';
import { LoggerService } from '../logger.service';
import { ISoftphoneHeaderInformation } from '../Model/ISoftphoneHeaderInformation';
import { StorageService } from '../storage.service';
import { TokenService } from '../token.service';
import { ServiceNowService } from '../service-now.service';
import {
  INTERACTION_DIRECTION_TYPES
} from '@amc-technology/davinci-api';
import { IActivity } from '../Model/IActivity';
import { bind } from 'bind-decorator';
import { IScenarioDetails } from '../Model/IScenarioDetails';
import { StorageServiceKeys } from '../Model/StorageServiceKeys';

@Component({
  selector: 'app-home',
  templateUrl: './home-servicenow.component.html',
  styleUrls: ['./home-servicenow.component.css']
})
export class HomeServicenowComponent extends Application implements OnInit {
  protected phoneNumberFormat: object;
  protected phoneNumberFormat2: { [key: string]: [string] };
  protected clickToDialPhoneReformatMap: object;
  protected quickCommentList: string[];
  protected accessToken: string;
  protected sn_entity_list: any[];
  protected display_list: any[];
  protected display_icon_path: string;
  protected InteractionSearchFields;
  protected QCAD_Info: any;
  protected QKeyInfoOut: any;
  protected QCAD_Interaction;
  protected davinciEntities;
  protected entityNameToItsConfigurations: any;
  protected entityNameToIconLocation: any;
  protected matchTypes;
  protected entityType: any;
  protected entityIcons: any[];
  protected activityFields: any;
  protected focusedScenario;
  protected autoPopulateScreenPop: any;
  protected AutoCloseInteractionRecord: boolean;

  protected screenpopOnInternal: boolean;
  protected screenpopOnOutbound: boolean;
  protected screenpopOnAlertingOrInitiated: boolean;
  protected openEntitiesOnMultimatch: boolean;

  private delayActivitySaveInSecond: number;
  private garbageCollectionFrequencyInSecond: number;
  private scenarioExpirationTimeInSecond: number;

  private garbageCollector: any;
  protected showActivityUI: boolean = false;
  protected showQuickCreateUI: boolean = false;

  private _fileName = 'home-servicenow.component.ts';

  private scneraioToSnInteraction: { [key: string]: object }

  protected currentScenarios: { [key: string]: IScenarioDetails };

  protected channelAppNames: string[];
  protected activeChannelName: string;

  protected entityFieldNamesInsideSnInteractionTable: { [key: string]: string };

  constructor(
    private loggerService: LoggerService,
    protected storageService: StorageService,
    private tokenService: TokenService,
    private serviceNowService: ServiceNowService
  ) {
    super(loggerService.logger);
    this.loggerService.logger.logDebug(
      'ServiceNowHomeComponent: constructor start'
    );
    this.storageService.syncWithLocalStorage();
    this.tokenService.accessTokenObservable.subscribe(token => {
      this.accessToken = token;
    });
    this.activityFields = null;
    this.delayActivitySaveInSecond = 0;
    this.garbageCollectionFrequencyInSecond = 60;
    this.scenarioExpirationTimeInSecond = 10000;
    this.scneraioToSnInteraction = {};
    this.currentScenarios = {};
    this.entityFieldNamesInsideSnInteractionTable = {};
  }

  async ngOnInit() {

    const fnName = 'ngOnInit';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      await this.loadConfig();
      this.appConfig = await this.storageService.getAppConfig();
      const AMCAppServiceNowURL: string = this.appConfig.variables.URL as string;
      const serviceNowInstanceURL: string = this.appConfig.variables
        .InstanceURL as string;
      this.serviceNowService.instanceURL = serviceNowInstanceURL;
      const clientId = this.appConfig.variables.ClientId;
      const clientSecret = this.appConfig.variables.ClientSecret;
      const access_token = localStorage.getItem(StorageServiceKeys.snAccessToken);
      const expires_in = Number.parseInt(localStorage.getItem(StorageServiceKeys.snAccessTokenExpiration));
      this.tokenService.setRefreshTokenParams(serviceNowInstanceURL, AMCAppServiceNowURL, clientId, clientSecret);
      if (!access_token || access_token === '' || (expires_in < Date.now())) {
        // This will open the token refresh window on startup if there is no token
        //TODO: make it better -> What if the access token is not valid? -> Probably we need to use the refreshToken from token.service.ts
        // this.tokenService.getNewAccessToken();
        // window.open(
        //   `https://ven02735.service-now.com/oauth_auth.do?response_type=code&redirect_uri=https://servicenow-test.contactcanvas.com&client_id=df03400937d42090bcc5d92ec4595b5d&state=123`,
        //   '_blank'
        // );
        this.tokenService.getNewAccessTokenWithRefreshToken();
      }

      this.phoneNumberFormat = this.appConfig.variables['PhoneNumberFormat'];
      this.clickToDialPhoneReformatMap = this.appConfig.variables['ClickToDialPhoneReformatMap'];
      this.quickCommentList = <string[]>this.appConfig.variables['QuickComments'];
      this.bridgeScripts = this.bridgeScripts.concat([
        this.getBridgeURL(),
        `${serviceNowInstanceURL}/scripts/openframe/1.0.4/openFrameAPI.min.js`
      ]);
      await super.ngOnInit();
      this.bridgeEventsService.subscribe('clickToDial', event => {
        api.clickToDial(this.clickToDialFormatPhoneNumber(event.number));
        this.bridgeEventsService.sendEvent('setOpenFrameVisibility', true);
      });
      api.registerOnLogout(this.removeLocalStorageOnLogout.bind(this));
      this.bridgeEventsService.sendEvent('passConfig', this.appConfig);
      this.davinciEntities = this.appConfig['Search Layout']['Entities'];

      this.entityNameToItsConfigurations = this.makeEntityNameToItsConfigurationMap(this.davinciEntities);


      this.InteractionSearchFields = this.splitInteractionSearchFields(
        this.appConfig['Search Layout']['variables'].InteractionSearchFields
      );

      this.AutoCloseInteractionRecord = false;
      if (this.appConfig['CallActivity']['variables']['AutoCloseInteractionRecord'] !== undefined) {
        this.AutoCloseInteractionRecord = this.appConfig['CallActivity']['variables']['AutoCloseInteractionRecord'];
      }

      this.showActivityUI = true;
      if (this.appConfig['CallActivity'] && this.appConfig['CallActivity']['variables']['ShowActivityUI'] !== undefined) {
        this.showActivityUI = this.appConfig['CallActivity']['variables']['ShowActivityUI'];
      }


      this.QCAD_Info = this.splitQuickCreateCADInfo(
        this.appConfig['QuickCreate']['variables'].QuickCreateCAD
      );

      this.showQuickCreateUI = true;
      if (this.appConfig['QuickCreate'] && this.appConfig['QuickCreate']['variables']['ShowQuickCreateUI'] !== undefined) {
        this.showQuickCreateUI = this.appConfig['QuickCreate']['variables']['ShowQuickCreateUI'];
      }

      //TODO we need all the undefined checking here!
      this.autoPopulateScreenPop = this.splitQuickCreateCADInfo(
        this.appConfig['Search Layout']['MatchTypes']['NoMatch']['variables'].autoPopulateScreenPop
      );
      this.QKeyInfoOut = this.keyInfoToArray(
        this.appConfig['QuickCreate']['variables'].QuickCreateKeyList
      );
      this.entityNameToIconLocation = this.makeEntityNameToIconLocationMap(this.QKeyInfoOut);

      if (this.appConfig['CallActivity']['variables']['ActivityExpirationTimeInSecond'] !== undefined) {
        this.scenarioExpirationTimeInSecond = this.appConfig['CallActivity']['variables']['ActivityExpirationTimeInSecond'] as number;
      }
      if (this.appConfig['CallActivity']['variables']['ActivityVerificationFrequencyInSecond'] !== undefined) {
        this.garbageCollectionFrequencyInSecond = this.appConfig['CallActivity']['variables']['ActivityVerificationFrequencyInSecond'] as number;
      }
      if (
        this.appConfig.hasOwnProperty('CallActivity') &&
        this.appConfig['CallActivity'].hasOwnProperty('variables') &&
        this.appConfig['CallActivity']['variables'].hasOwnProperty('ActivityFields')
      ) {
        this.activityFields =
          this.appConfig['CallActivity']['variables']['ActivityFields'];
      }

      for (const [key, value] of Object.entries(this.activityFields)) {
        if (value) {
          const keyString = key as string;
          const entityNameInsideSnInteractionTable = value as string;
          const stripString = 'AMC_RelatedTo_';
          if (keyString.startsWith(stripString)) {
            const tableName = keyString.substring(stripString.length);
            this.entityFieldNamesInsideSnInteractionTable[tableName] = entityNameInsideSnInteractionTable;
          }
        }
      }

      if (this.appConfig['CallActivity']['variables']['delayActivitySaveInSecond']) {
        this.delayActivitySaveInSecond = this.appConfig['CallActivity']['variables']['delayActivitySaveInSecond'];
      }


      if (this.appConfig.PhoneFormat) {
        this.phoneNumberFormat2 = this.appConfig.PhoneFormat.variables;
      } else {
        this.phoneNumberFormat2 = {};
      }

      this.screenpopOnInternal = false;
      if (this.appConfig['Search Layout'] &&
        this.appConfig['Search Layout']['variables']['ScreenpopOnInternal'] !== undefined) {
        this.screenpopOnInternal = this.appConfig['Search Layout']['variables']['ScreenpopOnInternal'];
      }


      this.screenpopOnOutbound = true;
      if (this.appConfig['Search Layout'] &&
        this.appConfig['Search Layout']['variables']['ScreenpopOnOutbound'] !== undefined) {
        this.screenpopOnOutbound = this.appConfig['Search Layout']['variables']['ScreenpopOnOutbound'];
      }

      this.screenpopOnAlertingOrInitiated = true;
      if (this.appConfig['Search Layout'] &&
        this.appConfig['Search Layout']['variables']['ScreenpopOnAlertingOrInitiated'] !== undefined) {
        this.screenpopOnAlertingOrInitiated = this.appConfig['Search Layout']['variables']['ScreenpopOnAlertingOrInitiated'];
      }

      this.openEntitiesOnMultimatch = false;
      if (this.appConfig['Search Layout'] &&
        this.appConfig['Search Layout']['MatchTypes']
        && this.appConfig['Search Layout']['MatchTypes']['MultiMatch']
        && this.appConfig['Search Layout']['MatchTypes']['MultiMatch']['variables']['OpenEntitiesOnMultimatch'] !== undefined) {
        this.openEntitiesOnMultimatch = this.appConfig['Search Layout']['MatchTypes']['MultiMatch']['variables']['OpenEntitiesOnMultimatch'];
      }



      this.display_list = [];
      this.matchTypes = this.appConfig['Search Layout']['MatchTypes'];
      this.entityIcons = [];
      this.channelAppNames = [];
      this.activeChannelName = "";


      this.garbageCollector = setInterval(this.garbageCollection, this.garbageCollectionFrequencyInSecond * 1000);

      api.registerForFrameworkNotifications(this.channelTabChanged.bind(this));

      api.registerOnLogout(this.cleanupOnLogout.bind(this));

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }


  }

  trackByFn(index: any, item: any) {
    return index;
  }

  getObjectKeys(obj: { [key: string]: IScenarioDetails }): any {
    return Object.keys(obj);
  }

  channelTabChanged(event: any) {
    if (this.channelAppNames.length === 0) {
      const arr = event.apps.substring(0, event.apps.length - 1).split(',');
      for (let i = 0; i < arr.length; i++) {
        this.channelAppNames.push(arr[i]);
      }
    }
    this.activeChannelName = event.newTabName;
  }

  cleanupOnLogout() {
    const fnName = 'cleanupOnLogout';
    try {
      this.loggerService.logger.pushLogsAsync();
      clearInterval(this.garbageCollector);
      localStorage.clear();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    }
  }

  makeEntityNameToItsConfigurationMap(davinciEntities: any) {
    const fnName = 'makeEntityNameToItsConfigurationMap';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      let result = {};
      let APIName: string = "";
      Object.keys(davinciEntities).forEach(element => {
        if (element !== 'variables') {
          APIName = this.davinciEntities[element]['variables'].APIName;
          if (APIName) {
            result[APIName] = this.davinciEntities[element];
          }
        }
      });
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  makeEntityNameToIconLocationMap(QCadInfo: any) {
    const fnName = 'makeEntityNameToIconLocationMap';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      let result = {};
      let APIName: string = "";
      for (let i = 0; i < QCadInfo.length; i++) {
        result[QCadInfo[i][0]] = QCadInfo[i][2];
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  @bind
  private async garbageCollection() {
    const fnName = 'garbageCollection';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      for (const scenarioId in this.currentScenarios) {
        let scenarioDetails = this.currentScenarios[scenarioId];
        let activity = scenarioDetails?.activity;

        // Check if LastUpdated is older than threshold
        let date = new Date();
        if ((date.getTime() - new Date(activity.LastUpdated).getTime()) > this.scenarioExpirationTimeInSecond * 1000) {
          await this.saveActivity2(this.currentScenarios[scenarioId], true);
          delete this.currentScenarios[scenarioDetails.scenarioId];
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  // Function to get the size of the object
  getCurrentScenarioSize(): number {
    const result = Object.keys(this.currentScenarios).length
    return result;
  }

  // Function to get the size of the object
  getFirstScenario(): IScenarioDetails {
    if (this.getCurrentScenarioSize() > 0) {
      let result = this.currentScenarios[Object.keys(this.currentScenarios)[0]];
      return result;
    }
    return undefined;
  }

  keyInfoToArray(QuickCreateKeyList: any): any {
    const fnName = 'keyInfoToArray';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      const arr = [];
      let i = 0;
      Object.keys(QuickCreateKeyList).forEach(element => {
        arr[i] = QuickCreateKeyList[element].split('|');
        arr[i].splice(0, 0, element);
        i++;
      });
      return arr;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  protected removeLocalStorageOnLogout(): Promise<any> {
    return new Promise(() => {
      localStorage.clear();
    });
  }

  protected formatPhoneNumber(inputNumber: string, phoneNumberFormat: Object): string {
    const fnName = 'formatPhoneNumber';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const configuredInputFormats = Object.keys(phoneNumberFormat);
      for (let index = 0; index < configuredInputFormats.length; index++) {
        let formatCheck = true;
        const inputFormat = configuredInputFormats[index];
        const outputFormat = phoneNumberFormat[inputFormat];
        if (inputFormat.length === inputNumber.length) {
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let j = 0; j < outputFormat.length; j++) {
              if (outputFormat[j] === 'x') {
                outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[j];
              }
            }
            this.logger.logTrace('ServiceNow - Home : END : Formatting Phone Number. Input Number : ' + inputNumber +
              '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + outputNumber);
            return outputNumber;
          }
        }
      }
      return inputNumber;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected formatPhoneNumber2(inputNumber: string, phoneNumberFormat: { [key: string]: [string] }): string[] {
    const fnName = 'formatPhoneNumber';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    let result = [inputNumber];
    try {
      for (const inputFormat in phoneNumberFormat) {
        if (inputFormat.length === inputNumber.length) {
          for (let i = 0; i < phoneNumberFormat[inputFormat].length; i++) {
            let outputFormat = phoneNumberFormat[inputFormat][i];
            let formatCheck = true;
            const arrInputDigits = [];
            let outputNumber = '';
            let outputIncrement = 0;
            if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
              continue;
            }
            for (let j = 0; j < inputFormat.length; j++) {
              if (inputFormat[j] === 'x') {
                arrInputDigits.push(j);
              } else if (inputFormat[j] !== '?' && inputNumber[j] !== inputFormat[j]) {
                formatCheck = false;
                break;
              }
            }
            if (formatCheck) {
              for (let j = 0; j < outputFormat.length; j++) {
                if (outputFormat[j] === 'x') {
                  outputNumber = outputNumber + inputNumber[arrInputDigits[outputIncrement]];
                  outputIncrement++;
                } else {
                  outputNumber = outputNumber + outputFormat[j];
                }
              }
              this.logger.logTrace('ServiceNow - Home : END : Formatting Phone Number. Input Number : ' + inputNumber +
                '. Configured Format : ' + JSON.stringify(phoneNumberFormat) + '. Output Number : ' + outputNumber);
              result.push(outputNumber);
            }

          }

        }
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected clickToDialFormatPhoneNumber(number: any) {

    const fnName = 'clickToDialFormatPhoneNumber';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const configuredInputFormats = Object.keys(this.clickToDialPhoneReformatMap);
      for (let i = 0; i < configuredInputFormats.length; i++) {
        let formatCheck = true;
        if (number.length === configuredInputFormats[i].length) {
          // Length of incoming number matches length of a configured input format
          // Now Validate # of X's in input/output
          const inputFormat = configuredInputFormats[i];
          const outputFormat = this.clickToDialPhoneReformatMap[configuredInputFormats[i]];
          const arrInputDigits = [];
          let outputNumber = '';
          let outputIncrement = 0;
          if (((inputFormat.match(/x/g) || []).length) !== ((outputFormat.match(/x/g) || []).length)) {
            continue;
          }
          if (((inputFormat.match(/\(/g) || []).length) !== ((number.match(/\(/g) || []).length)) {
            continue;
          }
          if (((inputFormat.match(/-/g) || []).length) !== ((number.match(/-/g) || []).length)) {
            continue;
          }
          for (let j = 0; j < inputFormat.length; j++) {
            if (inputFormat[j] === 'x') {
              arrInputDigits.push(j);
            } else if (inputFormat[j] !== '?' && number[j] !== inputFormat[j]) {
              formatCheck = false;
              break;
            }
          }
          if (formatCheck) {
            for (let k = 0; k < outputFormat.length; k++) {
              if (outputFormat[k] === 'x') {
                outputNumber = outputNumber + number[arrInputDigits[outputIncrement]];
                outputIncrement++;
              } else {
                outputNumber = outputNumber + outputFormat[k];
              }
            }
            return outputNumber;
          }
        }
      }
      return number;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  formatCrmResults(crmResults: any): api.SearchRecords {

    const fnName = 'formatCrmResults';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const ignoreFields = ['Name', 'displayName', 'object', 'Id', 'RecordType'];
      const result = new api.SearchRecords();
      if (!crmResults) {
        return result;
      }
      for (const id of Object.keys(crmResults)) {
        let recordItem: api.RecordItem = null;
        if (crmResults[id].Id && crmResults[id].RecordType) {
          recordItem = new api.RecordItem(
            crmResults[id].Id,
            crmResults[id].RecordType,
            crmResults[id].RecordType
          );
        } else if (crmResults[id].object && crmResults[id].displayName) {
          recordItem = new api.RecordItem(
            id,
            crmResults[id].object,
            crmResults[id].displayName
          );
        }
        if (recordItem !== null) {
          if (crmResults[id].Name) {
            if (recordItem.getMetadata().Type === 'Account') {
              recordItem.setAccountName('Name', 'Name', crmResults[id].Name);
            } else if (recordItem.getMetadata().Type === 'Contact') {
              recordItem.setFullName('Name', 'Name', crmResults[id].Name);
            } else {
              recordItem.setField('Name', 'Name', 'Name', crmResults[id].Name);
            }
          }
          for (const fieldName of Object.keys(crmResults[id])) {
            if (ignoreFields.indexOf(fieldName) < 0) {
              recordItem.setField(
                fieldName,
                fieldName,
                fieldName,
                crmResults[id][fieldName]
              );
            }
          }
          result.addSearchRecord(recordItem);
        }
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }


  /**
   * If there is a serviceNowInteraction and currentInteraction.interactionId equals to interaction.interactionId, updates the snInteraction
   * @param interaction
   * @param markAsComplete
   */
  private async updateSnInteraction(scenarioDetails: IScenarioDetails, markAsComplete = false) {

    const fnName = 'updateSnInteraction';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);


    try {
      const snInteractionPatch = {
        state: ''
      };
      if (markAsComplete) {
        snInteractionPatch.state = this.AutoCloseInteractionRecord ? 'closed_complete' : 'work_in_progress';
      } else {
        let interaction = scenarioDetails.interactions[scenarioDetails.lastInteractionId];
        switch (interaction.state) {
          case api.INTERACTION_STATES.Connected:
            snInteractionPatch.state = 'work_in_progress';
            break;
          case api.INTERACTION_STATES.OnHold:
            snInteractionPatch.state = 'on_hold';
            break;
          case api.INTERACTION_STATES.Disconnected:
            snInteractionPatch.state = markAsComplete ? 'closed_complete' : 'work_in_progress';
            break;
          case api.INTERACTION_STATES.Alerting:
            snInteractionPatch.state = 'new';
            break;
          case api.INTERACTION_STATES.Initiated:
            snInteractionPatch.state = 'new';
            break;
        }
      }

      const activity = scenarioDetails.activity;
      const activityFieldsKeys = Object.keys(this.activityFields);
      for (let i = 0; i < activityFieldsKeys.length; i++) {
        if (activity.hasOwnProperty(activityFieldsKeys[i])) {
          const ServiceNowFieldToSaveTo = this.activityFields[
            activityFieldsKeys[i]
          ];
          snInteractionPatch[
            ServiceNowFieldToSaveTo
          ] = activity[
            activityFieldsKeys[i]
            ];
        } else {
          let cadFields = activity.CadFields;
          if (cadFields.hasOwnProperty(activityFieldsKeys[i])) {
            const ServiceNowFieldToSaveTo = this.activityFields[
              activityFieldsKeys[i]
            ];
            snInteractionPatch[
              ServiceNowFieldToSaveTo
            ] = cadFields[
              activityFieldsKeys[i]
              ];
          }
        }
      }
      if (snInteractionPatch.hasOwnProperty('direction')) {
        snInteractionPatch['direction'] = snInteractionPatch['direction'].toLowerCase();
      }
      await this.serviceNowService.patchSnInteraction(
        scenarioDetails.snInteraction.sys_id,
        snInteractionPatch
      );
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Updates the focusedScenario and also updates the currentActivity in storageService
   * @param interaction
   */
  protected async updateScenarioID(interaction) {

    const fnName = 'updateScenarioID';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.focusedScenario = interaction.scenarioId;
      this.storageService.setCurrentActivity(this.storageService.activityList[this.focusedScenario]);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }


  }

  private async removeScenario(scenarioDetails: IScenarioDetails) {
    if (scenarioDetails.isCandidateForDeletion) {
      scenarioDetails.completed = true;
      await this.saveActivity2(scenarioDetails, true);
      delete this.currentScenarios[scenarioDetails.scenarioId];
    }
  }


  protected async onInteraction(interaction: api.IInteraction): Promise<api.SearchRecords> {
    const fnName = 'onInteraction';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    let result: api.SearchRecords;

    try {

      if (interaction.direction === api.INTERACTION_DIRECTION_TYPES.Internal) {
        if (!this.screenpopOnInternal) {
          return;
        }
      }

      const interactionId = interaction.interactionId;
      const scenarioId = interaction.scenarioId;
      let isNewScenarioId = false;

      if (!this.currentScenarios[scenarioId]) {
        if (interaction.state === api.INTERACTION_STATES.Disconnected) {
          return result;
        }
        let channelName = "";
        for (let chName of this.channelAppNames) {
          if (scenarioId.startsWith(chName)) {
            channelName = chName;
            break;
          }
        }
        this.currentScenarios[scenarioId] = {
          scenarioId: scenarioId,
          screenPopped: false,
          interactions: {},
          entities: [],
          completed: false,
          isCandidateForDeletion: false,
          tabDisplayString: "",
          iconSrc: "/assets/images/Miscellaneous_Icon.png",
          channelName: channelName
        }
        this.currentScenarios[scenarioId].interactions[interactionId] = interaction;
        this.currentScenarios[scenarioId].lastInteractionId = interactionId;

        let iconSrc = "";
        let tabDisplayString = "";
        switch (interaction.channelType) {
          case api.CHANNEL_TYPES.Telephony:
          case api.CHANNEL_TYPES.SMS:
            iconSrc = '/assets/images/Phone_Number_Icon.png';
            tabDisplayString = interaction.details.fields.Phone?.Value;
            break;
          case api.CHANNEL_TYPES.Email:
            iconSrc = '/assets/images/email.png';
            tabDisplayString = interaction.details.fields.Email?.Value;
            break;
          case api.CHANNEL_TYPES.Chat:
            iconSrc = '/assets/images/chat_symbol.png';
            tabDisplayString = interaction.details.fields.Email?.Value;
            break;
          default:
            iconSrc = '/assets/images/Miscellaneous_Icon.png';
        }

        this.currentScenarios[scenarioId].iconSrc = iconSrc;
        this.currentScenarios[scenarioId].tabDisplayString = tabDisplayString;



        let activity = this.createActivity(interaction);
        this.currentScenarios[scenarioId].activity = activity;
      } else {
        if (this.currentScenarios[scenarioId].isCandidateForDeletion) {
          this.currentScenarios[scenarioId].isCandidateForDeletion = false;
        }
      }
      this.currentScenarios[scenarioId].interactions[interactionId] = interaction;
      this.currentScenarios[scenarioId].lastInteractionId = interactionId;

      let scenarioDetails = this.currentScenarios[scenarioId];

      if (this.shouldPerformScreenpop2(scenarioDetails)) {
        await this.performScreenpop(interaction);
        return result;
        // } else if (interaction.state !== api.INTERACTION_STATES.Disconnected) {
        //   await this.saveActivity2(scenarioDetails);
      } else if (interaction.state === api.INTERACTION_STATES.Disconnected) {
        if (Object.keys(scenarioDetails.interactions).length === 1) {
          await this.saveActivity2(scenarioDetails);
          scenarioDetails.isCandidateForDeletion = true;
          delete scenarioDetails.interactions[interactionId];
          setTimeout(async () => {
            try {
              await this.removeScenario(scenarioDetails);
            } catch (error) {
              this.loggerService.logError(this._fileName, fnName + " setTimeout", error);
            }
          }, this.delayActivitySaveInSecond * 1000);
        } else {
          await this.saveActivity2(scenarioDetails);
        }
      } else {
        await this.saveActivity2(scenarioDetails);
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected shouldPerformScreenpop(interaction: api.IInteraction): boolean {
    let result = false;
    const screenPopOnInternal = true;
    const screenPopOnOutbound = false;
    if (this.currentScenarios[interaction.scenarioId].screenPopped) {
      return false;
    }
    if (interaction.direction === INTERACTION_DIRECTION_TYPES.Internal) {
      return screenPopOnInternal;
      // } else if (interaction.direction === INTERACTION_DIRECTION_TYPES.Outbound) {
      //   return screenPopOnOutbound;
    }
    return true;
  }

  protected shouldPerformScreenpop2(scenarioDetails: IScenarioDetails): boolean {
    if (scenarioDetails.screenPopped) {
      return false;
    }
    if (scenarioDetails.interactions[scenarioDetails.lastInteractionId].state === api.INTERACTION_STATES.Disconnected) {
      return false;
    }
    if (scenarioDetails.interactions[scenarioDetails.lastInteractionId].direction === INTERACTION_DIRECTION_TYPES.Outbound) {
      if (!this.screenpopOnOutbound) {
        return false;
      } else {
        if (scenarioDetails.interactions[scenarioDetails.lastInteractionId].state === api.INTERACTION_STATES.Initiated) {
          if (!this.screenpopOnAlertingOrInitiated)
            return false;
        }
      }
    } else if (scenarioDetails.interactions[scenarioDetails.lastInteractionId].direction === INTERACTION_DIRECTION_TYPES.Inbound) {
      if (scenarioDetails.interactions[scenarioDetails.lastInteractionId].state === api.INTERACTION_STATES.Alerting) {
        if (!this.screenpopOnAlertingOrInitiated) {
          return false;
        }
      }
    }
    return true;
  }

  // protected async onInteraction2(
  //   interaction: api.IInteraction
  // ): Promise<api.SearchRecords> {

  //   const fnName = 'onInteraction';
  //   this.loggerService.logFnPerimeter(this._fileName, fnName, true);

  //   try {
  //     const interactionId = interaction.interactionId;
  //     const scenarioIdInt = interaction.scenarioId;
  //     const scenarioId = interaction.scenarioId;

  //     if (
  //       this.storageService.recentActivityListContains(scenarioId) &&
  //       this.storageService.currentScenarioId !== scenarioId
  //     ) {
  //       this.saveActivity(scenarioId, true);
  //       return;
  //     }

  //     this.updateSnInteraction(interaction);
  //     let isNewScenarioId = false;
  //     if (
  //       (
  //         interaction.channelType === api.CHANNEL_TYPES.Telephony ||
  //         interaction.channelType === api.CHANNEL_TYPES.SMS
  //       ) &&
  //       interaction.state !== api.INTERACTION_STATES.Disconnected
  //     ) {
  //       interaction.details.fields.Phone.Value = this.formatPhoneNumber(
  //         interaction.details.fields.Phone.Value,
  //         this.phoneNumberFormat
  //       );
  //     }
  //     if (
  //       interaction.state === api.INTERACTION_STATES.Alerting ||
  //       interaction.state === api.INTERACTION_STATES.Initiated
  //       //TODO: state === api.INTERACTION_STATES.Initiated?
  //       // (interaction.state === api.INTERACTION_STATES.Connected &&
  //       //   interaction.direction === api.INTERACTION_DIRECTION_TYPES.Outbound)
  //     ) {
  //       this.bridgeEventsService.sendEvent(
  //         INTERACTION_TASKS.SetHeader,
  //         this.buildHeaderText(interaction)
  //       );
  //       this.bridgeEventsService.sendEvent('setOpenFrameVisibility', true);
  //     }
  //     isNewScenarioId = await this.processIfNewScenario(interaction);
  //     await this.updateScenarioID(interaction);

  //     this.storageService.updateInteractionDurationActivity(interaction.scenarioId, interaction.interactionId, interaction.state === api.INTERACTION_STATES.Disconnected);
  //     this.storageService.updateHoldInteractionActivityField(interaction.scenarioId, interaction.interactionId, interaction.state === api.INTERACTION_STATES.OnHold);

  //     if (
  //       !this.storageService.getCurrentInteraction() &&
  //       interaction.state !== api.INTERACTION_STATES.Disconnected
  //     ) {
  //       this.storageService.setCurrentInteraction(interaction);
  //       this.updateSnInteraction(interaction);
  //       return await this.performScreenpop(interaction);
  //     } else if (
  //       this.storageService.getCurrentInteraction() &&
  //       interaction.state !== api.INTERACTION_STATES.Disconnected
  //     ) {
  //       this.storageService.setCurrentInteraction(interaction);
  //       this.updateSnInteraction(interaction);
  //     } else if (
  //       interaction.state === api.INTERACTION_STATES.Disconnected) {
  //       this.bridgeEventsService.sendEvent(INTERACTION_TASKS.SetHeader, {
  //         interactionId: interaction.interactionId,
  //         connected: false
  //       });
  //       if (this.scenarioInteractionMappings[scenarioIdInt]) {
  //         delete this.scenarioInteractionMappings[scenarioIdInt][interactionId];
  //         if (Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
  //           .length === 0) {
  //           if (this.delayActivitySave > 0) {
  //             await new Promise<void>((resolve, reject) => {
  //               this.logger.logDebug(
  //                 'ServiceNow  - Home : START : Delaying Removing Activity. Scenario ID : ' +
  //                 interaction.scenarioId
  //               );
  //               let timer = setTimeout(() => {
  //                 resolve()
  //               }, this.delayActivitySave);
  //             });
  //           }
  //           this.saveActivity(scenarioId, true);
  //           this.updateSnInteraction(interaction, true);
  //           this.storageService.onInteractionDisconnect(scenarioId);
  //           this.sn_entity_list = [];
  //           this.storageService.serviceNowInteraction = null;
  //           delete this.scenarioInteractionMappings[interaction.scenarioId];
  //           delete this.storageService.activityList[interaction.scenarioId];
  //           this.storageService.activeScenarioIdList = this.storageService.activeScenarioIdList.filter(id => id !== scenarioId);
  //           this.storageService.storeCurrentInteractionToLocalStorage();
  //         }
  //         // if (
  //         //   Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
  //         //     .length === 0 && this.delayActivitySave > 0
  //         // ) {
  //         //   await new Promise<void>((resolve, reject) => {
  //         //     this.logger.logDebug(
  //         //       'ServiceNow  - Home : START : Delaying Removing Activity. Scenario ID : ' +
  //         //       interaction.scenarioId
  //         //     );
  //         //     let timer = setTimeout(() => {
  //         //       resolve()
  //         //     }, this.delayActivitySave);
  //         //   });
  //         // }
  //         // //If the scenario didn't receive an interaction it will close the activity
  //         // if (
  //         //   Object.keys(this.scenarioInteractionMappings[interaction.scenarioId])
  //         //     .length === 0
  //         // ) {
  //         //   this.saveActivity(scenarioId, true);
  //         //   this.updateSnInteraction(interaction, true);
  //         //   this.storageService.onInteractionDisconnect(scenarioId);
  //         //   this.sn_entity_list = [];
  //         //   this.storageService.serviceNowInteraction = null;
  //         //   delete this.scenarioInteractionMappings[interaction.scenarioId];
  //         //   delete this.storageService.activityList[interaction.scenarioId];
  //         //   this.storageService.activeScenarioIdList = this.storageService.activeScenarioIdList.filter(id => id !== scenarioId);
  //         //   this.storageService.storeCurrentInteractionToLocalStorage();
  //         // }
  //       }
  //     }
  //     return;
  //   } catch (error) {
  //     this.loggerService.logError(this._fileName, fnName, error);
  //   } finally {
  //     this.loggerService.logFnPerimeter(this._fileName, fnName);
  //   }

  // }

  /**
   * Saves the activity corresponding to the scenarioID into ServiceNow and removes the scenarioId from the map
   * @param scenarioId
   */
  // protected saveAndStoreActivity(scenarioId: string) {

  //   const fnName = 'saveAndStoreActivity';
  //   this.loggerService.logFnPerimeter(this._fileName, fnName, true);

  //   try {
  //     //TODO: saveActivity performs updateSnInteraction. Why we are calling it again?
  //     this.saveActivity(scenarioId, true);
  //     this.updateSnInteraction(this.storageService.getCurrentInteraction());
  //     this.storageService.onInteractionDisconnect(scenarioId);
  //     this.sn_entity_list = [];
  //     this.storageService.serviceNowInteraction = null;
  //     //TODO Cleanup the child objects first and then remove the parrent
  //     delete this.scenarioInteractionMappings[scenarioId];
  //   } catch (error) {
  //     this.loggerService.logError(this._fileName, fnName, error);
  //   } finally {
  //     this.loggerService.logFnPerimeter(this._fileName, fnName);
  //   }
  // }


  protected createActivity(interaction: api.IInteraction): IActivity {

    const fnName = 'createActivity';

    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const date = new Date();
      const activity: IActivity = {
        WhoObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        WhatObject: {
          objectType: '',
          displayName: '',
          objectName: '',
          objectId: '',
          url: ''
        },
        Subject: this.buildSubjectText(interaction),
        CallType:
          interaction.direction === api.INTERACTION_DIRECTION_TYPES.Inbound
            ? 'Inbound'
            : interaction.direction === api.INTERACTION_DIRECTION_TYPES.Outbound
              ? 'Outbound'
              : 'Internal',
        CallDurationInSeconds: 0,
        HoldDurationInSeconds: 0,
        DurationOnInteractions: {},
        HoldDurationOnInteractions: {},
        NumberOfHolds: 0,
        Description: '',
        Status: 'Open',
        ActivityDate: this.formatDate(date),
        TimeStamp: date,
        ActivityId: '',
        ScenarioId: interaction.scenarioId,
        InteractionId: interaction.interactionId,
        IsActive: true,
        LastUpdated: date,
        CadFields: {}
      };

      let fields = interaction.details?.fields;
      if (fields) {
        for (let key in fields) {
          activity.CadFields[key] = fields[key].Value;
        }
      }



      this.logger.logDebug(
        'ServiceNow - Home : New activity Info : ' + JSON.stringify(activity)
      );
      this.logger.logDebug(
        'ServiceNow - Home : END : Creating new Activity. Scenario ID : ' +
        interaction.scenarioId
      );
      return activity;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  protected getSecondsElapsed(startDate): number {
    const fnName = 'getSecondsElapsed';
    this.loggerService.logLoop(this._fileName, fnName, `startDate = ${startDate}`);

    try {
      const EndDate = new Date();
      if (typeof startDate === 'string') {
        startDate = new Date(startDate);
      }
      return Math.round((EndDate.getTime() - startDate.getTime()) / 1000);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logLoop(this._fileName, fnName, '');
    }
  }

  /**
   * If scenarioID is new and not disconnected
   * @param interaction
   * @returns
   */
  protected async processIfNewScenario(
    interaction: api.IInteraction
  ): Promise<boolean> {

    const fnName = 'processIfNewScenario';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      if (
        !this.scenarioInteractionMappings.hasOwnProperty(
          interaction.scenarioId
        ) &&
        interaction.state !== api.INTERACTION_STATES.Disconnected
      ) {
        this.scenarioInteractionMappings[interaction.scenarioId] = {};
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
        if (
          this.storageService.activeScenarioIdList.indexOf(
            interaction.scenarioId
          ) < 0
        ) {
          this.storageService.addActivity(this.createActivity(interaction));
          this.saveActivity(
            interaction.scenarioId,
            false,
          );
        }
        this.logger.logInformation(
          'ServiceNow - Home : New Scenario with Scenario ID : ' +
          interaction.scenarioId
        );
        this.logger.logTrace(
          'ServiceNow - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
          JSON.stringify(interaction)
        );
        return true;
      } else if (
        this.scenarioInteractionMappings.hasOwnProperty(interaction.scenarioId) &&
        !this.scenarioInteractionMappings[interaction.scenarioId].hasOwnProperty(interaction.interactionId) &&
        interaction.state !== api.INTERACTION_STATES.Disconnected
      ) {
        this.logger.logTrace(
          'ServiceNow - Home : Start : Found an existing scenario with no Interaction. Interaction Info : ' +
          JSON.stringify(interaction)
        );
        this.scenarioInteractionMappings[interaction.scenarioId][
          interaction.interactionId
        ] = true;
      }
      this.logger.logTrace(
        'ServiceNow - Home : END : Checking if the interaction is new or existing. Interaction Info : ' +
        JSON.stringify(interaction)
      );
      return false;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  protected buildSubjectText(interaction: api.IInteraction) {

    const fnName = 'buildSubjectText';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const channelType = api.CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          return `${channelType}[${fields.Email.Value}]`;
        } else if (fields.Phone) {
          return `${channelType}[${fields.Phone.Value}]`;
        } else if (fields.FullName) {
          return `${channelType}[${fields.FullName.Value}]`;
        }
      }
      return 'Unknown';
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected formatDate(date: Date): string {

    const fnName = 'formatDate';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = '' + date.getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      this.logger.logLoop(
        'ServiceNow - Home : END : Format Date. Input Date : ' + date
      );
      return year + '-' + month + '-' + day;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  protected buildHeaderText(interaction: api.IInteraction) {

    const fnName = 'buildHeaderText';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const headerInformation: ISoftphoneHeaderInformation = {
        connected: true,
        interactionId: interaction.interactionId
      };
      const channelType = api.CHANNEL_TYPES[interaction.channelType];
      if (interaction.details.fields) {
        const fields = interaction.details.fields;
        if (fields.Email) {
          headerInformation.title = channelType;
          headerInformation.contact = fields.Email.Value;
        } else if (fields.Phone) {
          headerInformation.title = channelType;
          headerInformation.contact = fields.Phone.Value;
        } else if (fields.FullName) {
          headerInformation.title = channelType;
          headerInformation.contact = fields.FullName.Value;
        }
      }
      return headerInformation;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  agentSelectedCallerInformation(event: { scenarioDetails: IScenarioDetails, entityIndex: number, associateEntity: boolean }) {
    this.ScreenPopOnEntityIndex(event);
  }

  protected async createNewHandler2(table: any, scenarioDetails: IScenarioDetails) {

    const fnName = 'createNewHandler';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);


    try {
      if (scenarioDetails !== undefined) {
        const cadInteractionDetails = {};
        const fields = scenarioDetails.interactions[scenarioDetails.lastInteractionId].details.fields;
        const interactionKeys = Object.keys(fields);
        if (interactionKeys.length > 0) {
          for (
            let i = 0;
            i < Object.keys(fields).length;
            i++
          ) {
            const key = Object.keys(fields)[i];
            for (let x = 0; x < this.autoPopulateScreenPop.length; x++) {
              if (key === this.autoPopulateScreenPop[x][0] && this.autoPopulateScreenPop[x][1] === table) {
                cadInteractionDetails[this.autoPopulateScreenPop[x][2]] = fields[
                  key
                ].Value;
              }
            }
          }
        } else {
          cadInteractionDetails[
            interactionKeys[0].toLowerCase()
          ] = fields[interactionKeys[0]].Value;
        }
        const queryString = this.createQueryString(cadInteractionDetails);

        if (scenarioDetails.snInteraction.sys_id) {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString,
            _sn_interactionId: scenarioDetails.snInteraction.sys_id
          });
        } else {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString
          });
        }
      } else {
        const cadInteractionDetails = {};
        const queryString = this.createQueryString(cadInteractionDetails);
        if (this.storageService.serviceNowInteraction && this.storageService.serviceNowInteraction.sys_id) {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString,
            _sn_interactionId: this.storageService.serviceNowInteraction.sys_id
          });
        } else {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString
          });
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  protected async createNewHandler(table: any) {

    const fnName = 'createNewHandler';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);


    try {
      if (this.QCAD_Interaction === undefined) {
        const cadInteractionDetails = {};
        cadInteractionDetails['phone'] = '';
        const queryString = this.createQueryString(cadInteractionDetails);
        if (this.storageService.serviceNowInteraction && this.storageService.serviceNowInteraction.sys_id) {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString,
            _sn_interactionId: this.storageService.serviceNowInteraction.sys_id
          });
        } else {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString
          });
        }
      } else {
        const cadInteractionDetails = {};
        const interactionKeys = Object.keys(this.QCAD_Interaction.details.fields);
        if (interactionKeys.length > 1) {
          for (
            let i = 0;
            i < Object.keys(this.QCAD_Interaction.details.fields).length;
            i++
          ) {
            const key = Object.keys(this.QCAD_Interaction.details.fields)[i];
            for (let x = 0; x < this.QCAD_Info.length; x++) {
              if (key === this.QCAD_Info[x][0] && this.QCAD_Info[x][1] === table) {
                cadInteractionDetails[this.QCAD_Info[x][2]] = this.QCAD_Interaction.details.fields[
                  key
                ].Value;
              }
            }
          }
        } else {
          cadInteractionDetails[
            interactionKeys[0].toLowerCase()
          ] = this.QCAD_Interaction.details.fields[interactionKeys[0]].Value;
        }
        const queryString = this.createQueryString(cadInteractionDetails);
        if (this.storageService.serviceNowInteraction && this.storageService.serviceNowInteraction.sys_id) {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString,
            _sn_interactionId: this.storageService.serviceNowInteraction.sys_id
          });
        } else {
          this.bridgeEventsService.sendEvent('createNewHandler', {
            table: table,
            query: queryString
          });
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  createQueryString(cadDetails: any) {

    const fnName = 'createQueryString';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      let queryString = '';
      Object.keys(cadDetails).forEach(element => {
        queryString += `${element}=${cadDetails[element]}^`;
      });
      if (queryString[queryString.length - 1] === '^') {
        queryString = queryString.substring(0, queryString.length - 1);
      }
      return queryString.replace(/\s/g, "");
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected getUserInfoHandler() {
    return this.bridgeEventsService.sendEvent('getUserInfo');
  }

  protected isToolbarVisible(): Promise<boolean> {
    return this.bridgeEventsService.sendEvent('isToolbarVisible');
  }

  protected getSearchLayout(): Promise<api.SearchLayouts> {
    this.loggerService.logger.logError(
      'servicenowhome Component: getSearchLayout not implemented '
    );
    throw new Error('Method not implemented.');
  }

  /**
 * Updates/adds the activity's field with the activityValue if the ScearioId is found
 * @param scenarioId
 * @param activityField
 * @param activityValue
 */
  public setActivityField(activity: IActivity, activityField: string, activityValue: any) {
    const fnName = 'setActivityField';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (activityField !== 'LastUpdated') {
        activity['LastUpdated'] = new Date();
      }

      activity[activityField] = activityValue;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }


  /**
   * Reads the activity from activityList and updates its fields IsActive and Status. Finally saves the activity inside ServiceNow
   * @param scenarioId
   * @param isComplete
   * @returns
   */
  protected saveActivity(scenarioId, isComplete = false): Promise<string> {

    const fnName = 'saveActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const activity = this.currentScenarios[scenarioId].activity;
      if (activity && activity.IsActive && isComplete) {
        // this.storageService.updateTotalInteractionTime(scenarioId);
        this.setActivityField(activity, 'IsActive', false);
        // this.storageService.updateTotalHoldTime(scenarioId);
      }

      const status = isComplete ? 'Completed' : 'Not Completed';
      this.setActivityField(activity, 'Status', status);

      this.setActivityField(activity, 'ActivityId', activity.ActivityId);

      // this.updateSnInteraction();
      return Promise.resolve(
        //TODO: You need to send the ID of the activity not the interaction!
        this.storageService.getCurrentInteraction()?.interactionId
      );
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
   * Reads the activity from activityList and updates its fields IsActive and Status. Finally saves the activity inside ServiceNow
   * @param scenarioId
   * @param isComplete
   * @returns
   */
  protected async saveActivity2(scenarioDetails: IScenarioDetails, isComplete = false): Promise<string> {
    const fnName = 'saveActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {

      if (!scenarioDetails.snInteraction) {
        return;
      }
      const activity = scenarioDetails.activity;
      if (activity && activity.IsActive && isComplete) {
        // this.storageService.updateTotalInteractionTime(scenarioId);
        this.setActivityField(activity, 'IsActive', false);
        // this.storageService.updateTotalHoldTime(scenarioId);
      }
      const status = isComplete ? 'Completed' : 'Not Completed';
      this.setActivityField(activity, 'Status', status);
      this.setActivityField(activity, 'ActivityId', activity.ActivityId);
      await this.updateSnInteraction(scenarioDetails, isComplete);
      return Promise.resolve(
        //TODO: You need to send the ID of the activity not the interaction!
        scenarioDetails.snInteraction?.sys_id
      );
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }
  /**
   * Reads the activity from activityList and updates its fields IsActive and Status. Finally saves the activity inside ServiceNow
   * @param scenarioId
   * @param isComplete
   * @returns
   */
  protected async updateScenarioDetailsNoteAndSave(scenarioDetails: IScenarioDetails, isComplete = false) {
    const fnName = 'saveActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.currentScenarios[scenarioDetails.scenarioId].activity = scenarioDetails.activity;
      this.saveActivity2(this.currentScenarios[scenarioDetails.scenarioId], false);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  private async getCustomerContact(interaction: api.IInteraction) {

    const fnName = 'getCustomerContact';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      let entities: any[] = [];
      this.display_list = [];
      this.QCAD_Interaction = interaction;

      // If there are more than just the phone number as CAD info
      if (Object.keys(interaction.details.fields).length > 0) {
        entities = await this.getEntitiesBasedOnCad(interaction);
        if (!entities || entities.length === 0) {
          entities = await this.getEntitiesBasedOnDefaultSearchFields(
            interaction
          );
        }
        // Else pop based on phone number alone (Multi-match)
      } else {
        entities = await this.getEntitiesBasedOnDefaultSearchFields(
          interaction
        );
      }

      // for (let entity in entities) {
      //   entity['scenarioId'] = interaction.scenarioId;
      // }

      return entities;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  async getEntitiesBasedOnCad(interaction: api.IInteraction): Promise<any[]> {
    const fnName = 'getEntitiesBasedOnCad';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const fields = interaction.details.fields;
      const result: any[] = [];
      for (let i = 0; i < Object.keys(fields).length; i++) {
        const key = Object.keys(fields)[i];
        for (let x = 0; x < this.InteractionSearchFields.length; x++) {
          if (key === this.InteractionSearchFields[x][0]) {
            const entities = await this.serviceNowService.getEntity(
              this.InteractionSearchFields[x][1],
              this.InteractionSearchFields[x][2],
              fields[key].Value
            );
            if (entities && entities.length !== 0) {
              for (let y = 0; y < entities.length; y++) {
                entities[y]['table'] = this.InteractionSearchFields[x][1];

                if (this.entityFieldNamesInsideSnInteractionTable.hasOwnProperty(this.InteractionSearchFields[x][1])) {
                  entities[y]['entityFieldNameInsideSnInteractionTable'] = this.entityFieldNamesInsideSnInteractionTable[this.InteractionSearchFields[x][1]];
                }

                let iconLocation = this.generateEntityIconLocation(entities[y]);
                entities[y]['AMC_iconLocation'] = iconLocation;

                let displayArray = this.generateEntityDisplay(entities[y]);
                entities[y]['AMC_displayConfig'] = displayArray;

                result.push(entities[y]);
              }

              Object.keys(this.davinciEntities).forEach(element => {
                if (
                  element !== 'variables' &&
                  this.davinciEntities[element]['variables'].APIName ===
                  this.InteractionSearchFields[x][1]
                ) {
                  this.display_list = this.getDisplayByEntity(element);
                }
              });
              return result;
            }
          }
        }
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  generateEntityIconLocation(entity: any): string {
    let iconLocation = '/assets/images/Miscellaneous_Icon.png';
    if (this.entityNameToIconLocation.hasOwnProperty(entity['table'])) {
      if (this.entityNameToIconLocation[entity['table']]) {
        iconLocation = this.entityNameToIconLocation[entity['table']];
      }
    }
    return iconLocation;
  }

  generateEntityDisplay(entity: any): [string, string][] {
    let displayArray: [string, string][] = [];
    if (this.entityNameToItsConfigurations.hasOwnProperty(entity['table'])) {
      const entityConfiguration = this.entityNameToItsConfigurations[entity['table']];
      if (entityConfiguration) {

        if (entityConfiguration?.DisplayFields?.variables) {
          const keyValues = entityConfiguration.DisplayFields.variables;
          for (let key in keyValues) {
            if (keyValues.hasOwnProperty(key)) {
              const value = keyValues[key];
              if (entity[value]) {
                displayArray.push([key, entity[value]]);
              }
            }
          }
        }
      }
    }
    return displayArray;
  }
  async getEntitiesBasedOnDefaultSearchFields(interaction: api.IInteraction): Promise<any[]> {

    const fnName = 'getEntitiesBasedOnDefaultSearchFields';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const fields = interaction.details.fields;
      const result = [];
      for (const key in this.davinciEntities) {
        if (key !== 'variables' && this.davinciEntities.hasOwnProperty(key)) {
          if (this.davinciEntities[key]['variables'].APIName !== undefined) {
            for (let j = 0; j < this.davinciEntities[key]['variables']['DefaultSearchFields'].length; j++) {
              if (fields.Phone?.Value) {
                let phoneNumbers = this.formatPhoneNumber2(fields.Phone.Value, this.phoneNumberFormat2);

                for (const phonenUmber of phoneNumbers) {
                  const entities = await this.serviceNowService.getEntity(
                    this.davinciEntities[key]['variables'].APIName,
                    this.davinciEntities[key]['variables']['DefaultSearchFields'][j],
                    phonenUmber
                  );
                  for (let i = 0; i < entities.length; i++) {
                    this.display_list = this.getDisplayByEntity(key);
                    entities[i]['table'] = this.davinciEntities[key]['variables'].APIName;

                    if (this.entityFieldNamesInsideSnInteractionTable.hasOwnProperty(this.InteractionSearchFields[i][1])) {
                      entities[i]['entityFieldNameInsideSnInteractionTable'] = this.entityFieldNamesInsideSnInteractionTable[this.InteractionSearchFields[i][1]];
                    }

                    let iconLocation = this.generateEntityIconLocation(entities[i]);
                    entities[i]['AMC_iconLocation'] = iconLocation;

                    let displayArray = this.generateEntityDisplay(entities[i]);
                    entities[i]['AMC_displayConfig'] = displayArray;
                    result.push(entities[i]);
                  }
                  if (result.length > 0) {
                    return result;
                  }
                }
              }
              if (fields.Email?.Value) {
                const entities = await this.serviceNowService.getEntity(
                  this.davinciEntities[key]['variables'].APIName,
                  this.davinciEntities[key]['variables']['DefaultSearchFields'][j],
                  fields.Email.Value
                );
                for (let i = 0; i < entities.length; i++) {
                  this.display_list = this.getDisplayByEntity(key);
                  entities[i]['table'] = this.davinciEntities[key]['variables'].APIName;



                  if (this.entityFieldNamesInsideSnInteractionTable.hasOwnProperty(this.InteractionSearchFields[i][1])) {
                    entities[i]['entityFieldNameInsideSnInteractionTable'] = this.entityFieldNamesInsideSnInteractionTable[this.InteractionSearchFields[i][1]];
                  }

                  let iconLocation = this.generateEntityIconLocation(entities[i]);
                  entities[i]['AMC_iconLocation'] = iconLocation;

                  let displayArray = this.generateEntityDisplay(entities[i]);
                  entities[i]['AMC_displayConfig'] = displayArray;

                  result.push(entities[i]);
                }
                if (result.length > 0) {
                  return result;
                }
              }
            }
          }
        }
      }
      return result;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }


  }

  getDisplayByEntity(key: string): any[] {

    const fnName = 'getDisplayByEntity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {

      const arr = [];
      Object.keys(
        this.davinciEntities[key]['DisplayFields']['variables']
      ).forEach(element => {
        arr.push(
          this.davinciEntities[key]['DisplayFields']['variables'][element] // - FOR VALUE
          // element // FOR KEY
        );
      });
      this.entityType = 'customer_' + key.toLowerCase();
      this.QKeyInfoOut.forEach(element => {
        if (element[0] === this.entityType) {
          this.entityIcons.push(element[2]);
        }
      });
      arr.splice(0, 0, key);
      return arr;

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  splitInteractionSearchFields(InteractionAttributesPop: string) {

    const fnName = 'splitInteractionSearchFields';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const arr = [];
      InteractionAttributesPop.split(';').forEach(element => {
        arr.push(element.split(','));
      });
      return arr;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  splitQuickCreateCADInfo(QCADInfo: string) {
    const arr = [];
    QCADInfo.split(';').forEach(element => {
      arr.push(element.split('|'));
    });
    return arr;
  }

  protected async performScreenpop2(scenarioDetails: IScenarioDetails) {

    const fnName = 'performScreenpop2';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      let interaction = scenarioDetails.interactions[scenarioDetails.lastInteractionId];
      scenarioDetails.screenPopped = true;
      this.entityIcons = [];
      const event = this.generateEventForScreenpop(interaction);
      // if (!this.storageService.serviceNowInteraction) {
      if (!scenarioDetails.snInteraction) {
        const sn_interaction = await this.serviceNowService.createSnInteraction(
          interaction
        );
        scenarioDetails.snInteraction = sn_interaction;
        let state = '';
        switch (interaction.state) {
          case api.INTERACTION_STATES.Connected:
            state = 'work_in_progress';
            break;
          case api.INTERACTION_STATES.OnHold:
            state = 'on_hold';
            break;
          case api.INTERACTION_STATES.Disconnected:
            state = 'closed_complete';
            break;
          case api.INTERACTION_STATES.Alerting:
            state = 'new';
            break;
          case api.INTERACTION_STATES.Initiated:
            state = 'new';
            break;
        }
        scenarioDetails.snInteraction = await this.serviceNowService.patchSnInteraction(
          scenarioDetails.snInteraction.sys_id,
          {
            assigned_to: sn_interaction.sys_created_by,
            state: state,
            direction: api.INTERACTION_DIRECTION_TYPES[interaction.direction]
          }
        );
      }
      event._sn_interactionId = scenarioDetails.snInteraction.sys_id;
      scenarioDetails.entities = await this.getCustomerContact(interaction);

      let screenpopResult;

      // If there is only one result from grabbing customercontacts
      if (scenarioDetails.entities.length === 1) {
        event._sn_customer_contact_id = scenarioDetails.entities[0].sys_id;
        const entityFieldNameInsideSnInteractionTable = scenarioDetails.entities[0].entityFieldNameInsideSnInteractionTable;
        this.serviceNowService.patchSnInteraction(
          scenarioDetails.snInteraction.sys_id,
          { [entityFieldNameInsideSnInteractionTable]: scenarioDetails.entities[0].sys_id }
        );
        event['screenPopTable'] = scenarioDetails.entities[0].table;
        screenpopResult = await this.bridgeEventsService.sendEvent(
          'screenPop',
          event
        );
        const records = this.formatCrmResults(undefined);
        return records;
        // If there are no contacts returned
      } else if (this.sn_entity_list.length < 1) {
        this.QCAD_Interaction = interaction;
        this.createNewHandler(
          this.matchTypes['NoMatch']['variables'].screenPopData
        );
      } else {
        this.display_list.splice(0, 0, 'Results');
      }

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected async performScreenpop(interaction: api.IInteraction) {

    const fnName = 'performScreenpop';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      let scenarioDetails = this.currentScenarios[interaction.scenarioId];
      scenarioDetails.screenPopped = true;
      this.entityIcons = [];
      const event = this.generateEventForScreenpop(interaction);
      // if (!this.storageService.serviceNowInteraction) {
      if (!scenarioDetails.snInteraction) {
        const sn_interaction = await this.serviceNowService.createSnInteraction(
          interaction
        );
        scenarioDetails.snInteraction = sn_interaction;
        let state = '';
        switch (interaction.state) {
          case api.INTERACTION_STATES.Connected:
            state = 'work_in_progress';
            break;
          case api.INTERACTION_STATES.OnHold:
            state = 'on_hold';
            break;
          case api.INTERACTION_STATES.Disconnected:
            state = 'closed_complete';
            break;
          case api.INTERACTION_STATES.Alerting:
            state = 'new';
            break;
          case api.INTERACTION_STATES.Initiated:
            state = 'new';
            break;
        }
        scenarioDetails.snInteraction = await this.serviceNowService.patchSnInteraction(
          scenarioDetails.snInteraction.sys_id,
          {
            assigned_to: sn_interaction.sys_created_by,
            state: state,
            direction: api.INTERACTION_DIRECTION_TYPES[interaction.direction]
          }
        );
      }
      event._sn_interactionId = scenarioDetails.snInteraction.sys_id;
      scenarioDetails.entities = await this.getCustomerContact(interaction);

      let screenpopResult;

      // If there is only one result from grabbing customercontacts
      if (scenarioDetails.entities.length === 1) {
        event._sn_customer_contact_id = scenarioDetails.entities[0].sys_id;
        const entityFieldNameInsideSnInteractionTable = scenarioDetails.entities[0].entityFieldNameInsideSnInteractionTable;
        this.serviceNowService.patchSnInteraction(
          scenarioDetails.snInteraction.sys_id,
          { [entityFieldNameInsideSnInteractionTable]: scenarioDetails.entities[0].sys_id }
        );

        event['screenPopTable'] = scenarioDetails.entities[0].table;
        screenpopResult = this.bridgeEventsService.sendEvent(
          'screenPop',
          event
        );
        const records = this.formatCrmResults(undefined);
        return records;
        // If there are no contacts returned
      } else if (scenarioDetails.entities.length < 1) {
        this.QCAD_Interaction = interaction;
        this.createNewHandler2(
          this.matchTypes['NoMatch']['variables'].screenPopData, scenarioDetails
        );
      } else {
        if (this.openEntitiesOnMultimatch) {
          this.ScreenPopOnEntityIndex({ scenarioDetails: scenarioDetails, entityIndex: 0, associateEntity: false });

          //We are adding this timeout because sending back to back requests to ServiceNow does not work!
          for (let idx = 1; idx < scenarioDetails.entities.length; idx++) {
            setTimeout(() => {
              this.ScreenPopOnEntityIndex({ scenarioDetails: scenarioDetails, entityIndex: idx, associateEntity: false });
            }, idx * 1000);
          }
        }

      }

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  private ScreenPopOnEntityIndex(data: { scenarioDetails: IScenarioDetails, entityIndex: number, associateEntity: boolean }) {
    const fnName = "ScreenPopOnEntityIndex";
    try {
      if (data.scenarioDetails === undefined) {
        return;
      }
      if (data.associateEntity) {

        const entityFieldNameInsideSnInteractionTable = data.scenarioDetails.entities[data.entityIndex].entityFieldNameInsideSnInteractionTable;
        this.serviceNowService.patchSnInteraction(
          data.scenarioDetails.snInteraction.sys_id,
          { [entityFieldNameInsideSnInteractionTable]: data.scenarioDetails.entities[data.entityIndex].sys_id }
        );
      }



      let interaction = data.scenarioDetails.interactions[data.scenarioDetails.lastInteractionId];
      const entity = data.scenarioDetails.entities[data.entityIndex];
      const event = this.generateEventForScreenpop(interaction);
      event._sn_interactionId = data.scenarioDetails.snInteraction.sys_id;
      event._sn_customer_contact_id = entity.sys_id;
      event['screenPopTable'] = entity.table;
      const screenpopResult = this.bridgeEventsService.sendEvent(
        'screenPop',
        event
      );
      const records = this.formatCrmResults(undefined);
      return records;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  private async screenpopOnId(entity: any) {

    const fnName = 'screenpopOnId';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      if (entity === undefined) {
        return;
      }
      let interaction = this.storageService.getCurrentInteraction();
      const event = this.generateEventForScreenpop(interaction);
      event._sn_interactionId = this.storageService.serviceNowInteraction.sys_id;

      event._sn_customer_contact_id = entity.sys_id;

      event['screenPopTable'] = entity.table;
      const screenpopResult = await this.bridgeEventsService.sendEvent(
        'screenPop',
        event
      );

      const records = this.formatCrmResults(undefined);

      return records;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }
}
