import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { StorageServiceKeys } from './Model/StorageServiceKeys';
import { HttpClient } from '@angular/common/http';
import { IOAuthTokenData } from './Model/IOauth2Model';
import { catchError, filter, finalize, map, switchMap, takeWhile } from 'rxjs/operators';
@Injectable()
export class TokenService {
  accessTokenObservable = new BehaviorSubject<string>('');
  serviceNowInstanceURL: string;
  AMCAppServiceNowURL: string;
  clientId: string;
  clientSecret: string;

  authCode: string
  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * 
   * @param accessToken 
   */
  setAccessToken(accessToken: string) {
    this.accessTokenObservable.next(accessToken);
  }


  getAccessToken(): string {
    return this.accessTokenObservable.value;
  }

  setRefreshTokenParams(
    serviceNowInstanceURL: string,
    AMCAppServiceNowURL: string,
    clientId: string,
    clientSecret: string
  ) {
    this.serviceNowInstanceURL = serviceNowInstanceURL;
    this.AMCAppServiceNowURL = AMCAppServiceNowURL;
    this.clientId = clientId;
    this.clientSecret = clientSecret;
  }

  storeAccessAndRefreshToken(tokenData: IOAuthTokenData) {
    localStorage.setItem(StorageServiceKeys.snAccessToken, tokenData.access_token);
    localStorage.setItem(StorageServiceKeys.snRefreshToken, tokenData.refresh_token);
    this.setAccessToken(tokenData.access_token);

    const expires_in_seconds = Number.parseInt(tokenData.expires_in);
    const expires_in = (Date.now() + expires_in_seconds * 1000) + "";
    localStorage.setItem(StorageServiceKeys.snAccessTokenExpiration, expires_in);

  }

  getNewAccessTokenWithRefreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.snRefreshRequestIsSentAlready)) {
        localStorage.setItem(StorageServiceKeys.snRefreshRequestIsSentAlready, "true");
        let refresh_token = localStorage.getItem(StorageServiceKeys.snRefreshToken);
        try {
          this._http.post('RefreshToken', {
            client_id: this.clientId,
            client_secrect: this.clientSecret,
            instance_url: this.serviceNowInstanceURL,
            refresh_token: refresh_token
          }).subscribe(
            response => {
              this.storeAccessAndRefreshToken(response);
              localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
              resolve();
            },
            async (error) => {
              if (error.status === 400 && error.error === "No refresh token! Open auth window") {
                try {
                  await this.getNewAccessTokenByOpeningNewWindow();
                  localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                  resolve();
                } catch (_) {
                  localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                  reject();
                }
              } else {
                localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
                reject();
              }
            }
          )
        } catch (_) {
          localStorage.removeItem(StorageServiceKeys.snRefreshRequestIsSentAlready);
          reject();
        }
      } else {
        let timeout = 30;
        let timer = setInterval(async () => {
          // TODO1: what if the agent closes the window?
          if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
            clearInterval(timer);
            resolve();
          } else {
            timeout--;
            if (timeout <= 0) {
              clearTimeout(timer)
              reject();
            }
          }
        }, 1000);
      }

    })
  }


  getNewAccessTokenWithAuthCode(): Promise<void> {
    return new Promise((resolve, reject) => {
      const code = this.authCode;

      if (!localStorage.getItem(StorageServiceKeys.snAuthRequestIsSentAlready)) {
        localStorage.setItem(StorageServiceKeys.snAuthRequestIsSentAlready, "true");
        if (code) {
          try {
            this._http.post('Auth', {
              code: code,
              clientId: this.clientId,
              clientSecret: this.clientSecret,
              instanceUrl: this.serviceNowInstanceURL,
              redirectUri: this.AMCAppServiceNowURL
            }).subscribe(
              response => {
                this.storeAccessAndRefreshToken(response);
                resolve();
                localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
              },
              _ => {
                // Handle errors
                localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
                reject();
              }
            )
          } catch (error) {
            localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
            reject();
          }
        } else {
          localStorage.removeItem(StorageServiceKeys.snAuthRequestIsSentAlready);
          reject();
        }
      } else {
        setTimeout(() => {
          if (!localStorage.getItem(StorageServiceKeys.snAuthRequestIsSentAlready)) {
            if (localStorage.getItem(StorageServiceKeys.snAccessToken)) {
              resolve();
            } else {
              reject();
            }
          }
        }, 1000);
      }

    })
  }

  openAuthCodeWindow(state: string) {
    window.open(
      `${this.AMCAppServiceNowURL}/redirectagent?state=${state}`,
      // `${this.serviceNowInstanceURL}/oauth_auth.do?response_type=code&redirect_uri=${this.AMCAppServiceNowURL}&client_id=${this.clientId}&state=${state}`,
      '_blank'
    );
  }


  getNewAccessTokenByOpeningNewWindow(): Promise<void> {
    // This function is called when the error for an expired token is received
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem(StorageServiceKeys.snAuthWindowOpened)) {
        localStorage.setItem(StorageServiceKeys.snAuthWindowOpened, "true");

        const body = {
          ServiceNowInstanceURL: `${this.serviceNowInstanceURL}`,
          AMCAppServiceNowURL: `${this.AMCAppServiceNowURL}`,
          ClientId: `${this.clientId}`
        }

        this._http.post<string>("/guids", body).subscribe(
          (response) => {
            this.openAuthCodeWindow(response["guid2"]);
            this.pollCodeEndpoint(response, 30).subscribe(
              async (pollResponse) => {
                if (pollResponse && pollResponse.success) {
                  console.log('Polling successful, response:', pollResponse);
                  this.authCode = pollResponse.response;
                  // Handle the successful polling response here
                  await this.getNewAccessTokenWithAuthCode();
                  resolve();
                } else {
                  reject();
                }
              },
              (error) => {
                console.error('Error in polling GET request:', error);
                reject();
              }
            );
          },
          (error) => {
            console.error("AhmadMansouri", error);
            reject();
          }
        )

      }

      // let timeout = 30;
      // let timer = setInterval(async () => {
      //   // TODO1: what if the agent closes the window?
      //   if (!localStorage.getItem(StorageServiceKeys.snAuthWindowOpened)) {
      //     clearInterval(timer);
      //     const code = localStorage.getItem(StorageServiceKeys.snAuthorizationCode);
      //     if (code) {
      //       try {
      //         await this.getNewAccessTokenWithAuthCode();
      //         resolve();
      //       } catch (error) {
      //         reject();
      //       }
      //     }
      //   } else {
      //     timeout--;
      //     if (timeout <= 0) {
      //       clearInterval(timer);
      //       window.localStorage.setItem(
      //         StorageServiceKeys.snAccessToken,
      //         window.location.hash
      //       );
      //       reject();
      //     }
      //   }
      // }, 1000);
    });
  }


  pollCodeEndpoint(guidresponse: any, maxAttempts: number): Observable<any> {
    let attempts = 0;

    return timer(0, 1000).pipe(
      switchMap(() => {
        return this._http.post("/getcode", {
          "Guid1": guidresponse.guid1,
          "Guid2": guidresponse.guid2,
        }).pipe(
          catchError(error => {
            console.error('Polling GET request failed', error);
            return of(null); // Return null on error to keep polling
          })
        );
      }),
      map(response => {
        attempts++;
        if (response && response.status === 200) {
          console.log('Successful response received, stopping polling.');
          return { success: true, response }; // Return success and the response
        } else if (attempts >= maxAttempts) {
          console.log('Maximum attempts reached, stopping polling.');
          return { success: false, message: 'Maximum attempts reached' }; // Return failure message
        }
        return null; // Continue polling
      }),
      takeWhile(result => result === null), // Continue until a non-null result is received
      finalize(() => {
        if (attempts >= maxAttempts) {
          console.error('Polling completed without success.');
        }
      })
    ).pipe(
      map(result => {
        // Return either the successful response or the failure message
        return result ? result : { success: false, message: 'Polling stopped without success' };
      })
    );
  }
  // Method to poll GET endpoint
  // pollCodeEndpoint(guidresponse: any, maxAttempts: number): Observable<any> {
  //   let attempts = 0;
  //   //TODO: update the interval
  //   return timer(0, 1000).pipe(
  //     switchMap(() => {
  //       return this._http.post(
  //         "/getcode",
  //         {
  //           "Guid1": guidresponse.guid1,
  //           "Guid2": guidresponse.guid2,
  //         }

  //       ).pipe(
  //         catchError(error => {
  //           console.error('Polling GET request failed', error);
  //           return of(null);  // Handle error by returning a default value
  //         })
  //       );
  //     }),
  //     takeWhile(response => {
  //       attempts++;
  //       // Stop polling if we get a successful response or if max attempts reached
  //       return (!response || response.status !== 200) && attempts < maxAttempts;
  //     }),
  //     map(response => {
  //       if (response) {
  //         console.log('Successful response received, stopping polling.');
  //       }
  //       return response;
  //     })
  //   );
  // }


}
