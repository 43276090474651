/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { IActivity } from '../models/Activity';

/**
 * Apps that integrate with an application should register for this to create an activity/notes when the event is received.
 *
 * See [[saveActivity]]
 * @param callback This function should create or update an activity in the application.
 * If the id of the given activity is defined then this function should update the existing activity,
 * else this should create a new activity.
 */
export function registerSaveActivity(callback: (activity: IActivity) => Promise<string>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SAVE_ACTIVITY);
}

/**
 * Apps that create interactions should use this to save, or update, information about the interaction
 * (notes, disposition, etc...) on the application/crm.
 *
 * If the id of the activity is defined then the existing activity will be updated, otherwise a new activity will be created.
 *
 * See [[registerSaveActivity]]
 * @param activity
 * @returns Id of the created or updated activity
 */
export function saveActivity(activity: IActivity): Promise<string> {
  return sendRequestToFramework(OPERATIONS.SAVE_ACTIVITY, [activity]);
}
