import { LOG_LEVEL } from '../Logger/LogLevel';
import { LOGGER_TYPE } from '../Logger/LoggerType';

export interface ILoggerConfiguration {
  'Logger Type': string;
  'Log Level': number;
  'Premise Logger URL': string;
  'Console Logger': IConsoleLoggerConfiguration;
}

export interface IConsoleLoggerConfiguration {
  'Max Length': number;
}

export const defaultConsoleLoggerConfiguration: IConsoleLoggerConfiguration = {
  'Max Length': 400
};

export const defaultLoggerConfiguration: ILoggerConfiguration = {
  'Logger Type': LOGGER_TYPE.DAVINCI,
  'Log Level': LOG_LEVEL.None,
  'Premise Logger URL': '',
  'Console Logger': defaultConsoleLoggerConfiguration
};
