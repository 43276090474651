import { Injectable, isDevMode } from '@angular/core';
import {
  Logger,
  LOG_SOURCE,
  AbstractLogger,
  getDaVinciAgentConfig,
  LOGGER_TYPE,
  ConsoleLogger,
  FileLogger,
  PremiseLogger
} from '@amc-technology/davinci-api';
import { ConfigurationService } from './configuration.service';
import {
  ILoggerConfiguration,
  defaultLoggerConfiguration
} from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

@Injectable()
export class LoggerService {

  private _logger?: Logger;
  private _fileName = 'logger.service.ts';

  get logger() {
    if (!this._logger) {
      throw new Error('Logger not initialized!');
    }
    return this._logger;
  }

  constructor(private configService: ConfigurationService) { }
  async initialize() {
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();

      // Perform data validation, revert to defaults if configs are invalid
      // or undefined

      let logLevel = parseInt(
        rawConfig?.variables?.['Log Level']?.toString(),
        10
      );

      logLevel = isNaN(logLevel)
        ? defaultLoggerConfiguration['Log Level']
        : logLevel;

      let maxLength = parseInt(
        rawConfig?.['Console Logger']?.variables?.['Max Length']?.toString(),
        10
      );

      maxLength = isNaN(maxLength)
        ? defaultLoggerConfiguration['Console Logger']['Max Length']
        : maxLength;

      const loggerConfig: ILoggerConfiguration = {
        'Log Level': logLevel,
        'Logger Type':
          rawConfig?.variables?.['Logger Type']?.toString() ||
          defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL':
          rawConfig?.variables?.['Premise Logger URL']?.toString() ||
          defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': maxLength
        }
      };

      this._logger = new Logger(
        LOG_SOURCE.ServiceNowApp,
        false,
        this.configService.config.loggerApiUrl
      );

      this._logger.setConfiguration(loggerConfig);
    } catch (err) {
      this._logger = new Logger(
        LOG_SOURCE.ServiceNowApp,
        false,
        this.configService.config.loggerApiUrl
      );

      this.logger.logCritical(
        'loggerService.initialize(): Error creating logger!'
      );
    }
  }

  /**
  * Add logs for the start or end of a function
  * @param isStart: defines whether it is START or END. Default value is false.
  * @param fnName fnName: function's name
  * @param fileName: the file name the funcion is in
  */
  logFnPerimeter(fileName: string = "", fnName: string = "", isStart = false) {
    const _fnName = 'logFunctionPerimeter';
    const _pType = isStart ? 'START' : 'END'; // This defines start or end
    try {
      this._logger.logDebug(`${fileName} - ${fnName} - ${_pType}`);
    } catch (error) {
      this.logError(this._fileName, _fnName, error);
    }
  }

  /**
  * Logs the error
  * @param fileName filename: the file name the funcion is in
  * @param fnName fnName: function's name
  * @param error error: error object
  */
  logError(fileName: string = "", fnName: string = "", error: any) {
    const _fName = 'logError';
    try {
      this._logger.logError(`${fileName} - ${fnName} - ERROR: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
    } catch (error) {
      this._logger.logTrace(`${this._fileName} - ${_fName} - Something strange is going on"`);
    }
  }


  /**
  * Logs the message inside a loop
  * @param fileName filename: the file name the funcion is in
  * @param fnName fnName: function's name
  * @param message message: message string
  */
  logLoop(fileName: string = "", fnName: string = "", message: any) {
    const _fName = 'logError';
    try {
      this._logger.logLoop(`${fileName} - ${fnName} - MESSAGE1: ${JSON.stringify(message)}`);
    } catch (error) {
      this._logger.logError(`${this._fileName} - ${_fName} - Something strange is going on"`);
    }
  }



}
