/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * Call this when a queue information is updated.
 *
 * Example: if data in the queue has been modified, the new queue information needs to be sent to the agent to see.
 *
 * - See [[registerQueueUpdate]]
 * @param queue An object of type any
 */
export function queueUpdate(queue: any): Promise<void> {
  return sendRequestToFramework(OPERATIONS.QUEUE_UPDATE, [queue]).then((val) => {}); // make sure we return void
}
/**
 * Apps that handle queues should use this to recieve a new queue object when a queue is updated.
 *
 * - See [[queueUpdate]]
 * @param callback Called whenever an user preforms a queue update
 */
export function registerQueueUpdate(callback: (queue: any) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.QUEUE_UPDATE);
}

/**
 * Call this when an action on a queue is performed, an object is returned.
 *
 * - See [[registerQueueAction]]
 * @param queue An object of type any
 */
export function queueAction(queue: any): Promise<void> {
  return sendRequestToFramework(OPERATIONS.QUEUE_ACTION, [queue]).then((val) => {}); // make sure we return void
}
/**
 * Apps that handle queue actions should use this to register when an action on a queue is performed.
 *
 * - See [[queueAction]]
 * @param callback Called whenever an user preforms a queue action
 */
export function registerQueueAction(callback: (queue: any) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.QUEUE_ACTION);
}
