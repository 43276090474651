import { Component, OnInit, Input, ElementRef, EventEmitter, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { IActivity } from '../Model/IActivity';
import { IScenarioDetails } from '../Model/IScenarioDetails';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit, OnChanges {
  protected isMaximized: boolean;
  @Input() scenarioId: string;
  @Input() scenarioDetails: IScenarioDetails;
  @Input() quickCommentList: string[];
  @Input() description: string;
  @Output() saveActivity: EventEmitter<IScenarioDetails> = new EventEmitter<IScenarioDetails>();

  protected internalDescription: string;
  private _fileName = 'activity.component.ts';

  constructor(
    protected storageService: StorageService,
    private _doc: ElementRef,
    protected loggerService: LoggerService,
    private _cd: ChangeDetectorRef
  ) {
    this.isMaximized = true;
  }

  ngOnChanges() {
    // Initialize the internalValue with the value from the parent
    this.internalDescription = this.description ?? "";
  }

  ngOnInit() {
  }

  public appendDescription(value: string) {
    const fnName = 'appendDescription';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.internalDescription += (value);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }


  protected loadComment(index: number) {
    const fnName = 'loadComment';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (this.internalDescription.length > 0) {
        this.internalDescription += ('\n' + this.quickCommentList[index]);
      } else {
        this.internalDescription += (this.quickCommentList[index]);
      }
      this._cd.detectChanges();
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  protected parseSearchRecord(searchRecord) {
    const fnName = 'parseSearchRecord';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      const keys = Object.keys(searchRecord.fields);
      let nameKey;
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].includes('Name')) {
          nameKey = keys[i];
          break;
        }
      }
      let name = searchRecord.fields[nameKey].Value;
      name = searchRecord.displayName
        ? searchRecord.displayName + ': ' + name
        : searchRecord.RecordType + ': ' + name;
      return name;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  protected submit() {
    this.loggerService.logger.logDebug('Oracle: Notes submit');
  }

  protected onCallNotesChange(event: any) {
    this.internalDescription = event.srcElement.value.trim();
    this._cd.detectChanges();
  }

  protected submitActivity() {
    const fnName = 'submitActivity';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.scenarioDetails.activity.Description = this.internalDescription;
      this.saveActivity.emit(this.scenarioDetails);
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    }
    this.loggerService.logFnPerimeter(this._fileName, fnName);
  }
}
