/* eslint-disable max-len */
/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import { sendRequestToFramework } from '../HelperFunctions';

/**
 * Set the height of this app.
 * Whenever the height needed by your app changes you should recalculate it and call this method.
 * @param height Height in px
 * @param grayOutLowerLayers If true then DaVinci will gray out all apps in lower layers then this app. Only applies if 'Layer' config in studio is greater than 0 and height is greater than 0.
 */
export function setAppHeight(height: number, grayOutLowerLayers: boolean = false): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SET_SOFTPHONE_HEIGHT, [height, grayOutLowerLayers]).then((val: any) => {}); // make sure we return void
}
/**
 * Set the width of this app
 * Whenever the width needed by your app changes you should recalculate it and call this method.
 *
 * Note: this is can only be used if the location of you app as defined in Creates Studio is
 * Top Left, Top Right, Bottom Left, or Bottom Right.
 * @param width Width in px
 */
export function setAppWidth(width: number): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SET_SOFTPHONE_WIDTH, [width]).then((val: any) => {}); // make sure we return void
}
