/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework, ofLogger } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { IAppConfiguration } from '../models/AppConfiguration';

/**
 * This is needed to get configuration of the app from Creators Studio.
 *
 * @returns The configuration of the app provided in DaVinci Creators Studio
 */
export function getConfig(): Promise<IAppConfiguration> {
  return sendRequestToFramework(OPERATIONS.GET_CONFIG).then((resolved) => resolved);
}
