export function safeJSONParse(obj: any) {
  try {
    return JSON.parse(obj);
  } catch (e) {
    return null;
  }
}

export function parsePostHashQuery(url: string) {
  let searchParams = new URL(url).searchParams;
  let result = {};
  searchParams.forEach((value, key, parent) => {
    result[key] = value;
  })
  return result;
}
