import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LoggerService } from '../logger.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IScenarioDetails } from '../Model/IScenarioDetails';
@Component({
  selector: 'app-create',
  templateUrl: './create-servicenow.component.html',
  styleUrls: ['./create-servicenow.component.css']
})
export class CreateServicenowComponent implements OnInit, OnChanges {
  @Output() CreateNewEntity: EventEmitter<{ type: string, scenarioDetails: IScenarioDetails }> = new EventEmitter<{ type: string, scenarioDetails: IScenarioDetails }>();
  @Input() QKeyInfo: any[];
  @Input() ScenarioDetails: IScenarioDetails;
  isMaximizeCreate: boolean;
  constructor(private loggerService: LoggerService, private router: Router) {
    this.isMaximizeCreate = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngOnInit() {
  }
  protected createNewEntity(type) {
    this.CreateNewEntity.emit({ type: type, scenarioDetails: this.ScenarioDetails });
  }
}
