/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework, sendRegisterRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * Opens a new tab in the browser.
 * This is originally used to open 'My Calls Today' a list of calls the agent had taken today.
 * @param url The url to open.
 */
export function openMyCallsToday(url: string, openInNewWindow: boolean): Promise<void> {
  return sendRequestToFramework(OPERATIONS.MY_CALLS_TODAY, [url, openInNewWindow]).then((val) => {}); // make sure we return void
}

/**
 * Apps that integrate with an application/CRM should register for this
 *
 * See [[openMyCallsToday]]
 * @param callback This function should try to open the mycalls today link inside the crm. Ideally in a new CRM tab(not browser tab)
 */
export function registerOpenMyCallsToday(callback: (url: string, openInNewWindow: boolean) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.MY_CALLS_TODAY);
}
