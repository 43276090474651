/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * Used by apps that embed agent.contactcanvas.com into a CRM.
 * This registers the app to handle events to change the iframe's height.
 * @param callback Called when the height of agent.contactcanvas.com changes.
 * The application should set the height of the iframe containing agent.contactcanvas.com to the given height(in px).
 */
export function registerSetSoftphoneHeight(callback: (height: number) => Promise<void>) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SET_SOFTPHONE_HEIGHT);
}

/**
 * Used by apps that embed agent.contactcanvas.com into a CRM.
 * This registers the app to handle events to change the iframe's height.
 * @param callback Called when the width of agent.contactcanvas.com changes.
 * The application should set the width of the iframe containing agent.contactcanvas.com to the given width(in px).
 */
export function registerSetSoftphoneWidth(callback: (width: number) => Promise<void>) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SET_SOFTPHONE_WIDTH);
}

/**
 * Used by apps that embed agent.contactcanvas.com into a CRM.
 * This registers the app to handle events to check if agent.contactcanvas.com is currently visible.
 * @param callback
 * This function when called should return(vis a promise) whether or not agent.contactcanvas.com is currently visible to the user.
 * e.g. if agent.contactcanvas.com is minimized this should return false and if it is maximized it should return true
 *
 * See [[isToolbarVisible]]
 */
export function registerIsToolbarVisible(callback: () => Promise<boolean>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.IS_TOOLBAR_VISIBLE);
}

/**
 * This checks if the toolbar(agnet.contactcanvas.com) is visible.
 *
 * See [[registerIsToolbarVisible]]
 * @returns true if the toolbar(agent.contactcanvas.com) is visible(maximized), or false otherwise(minimized)
 */
export function isToolbarVisible(): Promise<boolean> {
  return sendRequestToFramework(OPERATIONS.IS_TOOLBAR_VISIBLE);
}

/**
 * This is used to enabled and disable the toolbar. If disabled then only global apps will load.
 * @param isEnabled
 */
export function setToolbarEnabled(isEnabled: boolean): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SET_TOOLBAR_ENABLED, [isEnabled]).then((val) => {}); // make sure we return void
}
