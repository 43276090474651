/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * Checks if this app is currently visible.
 */
export function isPluginVisible(): Promise<boolean> {
  return sendRequestToFramework(OPERATIONS.IS_PLUGIN_VISIBLE);
}

/**
 * Sets if or not the app should be visible.
 * @param isVisible
 */
export function setPluginVisible(isVisible: boolean): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SET_PLUGIN_VISIBLE, [isVisible]).then((val) => {}); // make sure we return void
}
