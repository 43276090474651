/**
 * @packageDocumentation
 * @module DaVinci_API
 */

import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';

import { NOTIFICATION_TYPE } from '../models/NotificationType';
import { OPERATIONS } from '../models/Operations';

/**
 * This sends notification to framework which will be displayed in toolbar.
 * To Do : Different notifications based on type.
 * @param message Message to be displayed on toolbar.
 * @param notificationType Type of notification.
 */
export function sendNotification(message: string, notificationType?: NOTIFICATION_TYPE): Promise<void> {
  return sendRequestToFramework(OPERATIONS.NOTIFICATION_TO_FRAMEWORK, [message, notificationType]).then((val) => {}); // make sure we return void
}
/**
 * Apps that handle displaying notifications should use this to register to receive notifications.
 *
 * - See [[sendNotification]]
 * @param {(message: string, notificationType?: NOTIFICATION_TYPE) => Promise<void>} callback
 */
export function registerSendNotification(
  callback: (
    message: string,
    notificationType?: NOTIFICATION_TYPE
  ) => Promise<void>
): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.NOTIFICATION_TO_FRAMEWORK);
}
