/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { OPERATIONS } from '../models/Operations';
import { sendRequestToFramework, sendRegisterRequestToFramework } from '../HelperFunctions';
import { RecordItem } from '../models/RecordItem';
import { SearchRecords } from '../models/SearchRecords';
import { IInteraction } from '../models/Interactions';

/**
 * Apps with interactions(phone calls, chats, etc..) should call this every time an interactions is added, removed, or modified.
 *
 * This is important as it allows for screenpops, activities to be saved in CRM, and statistics.
 * Calling setIntraction results in a change to the Interaction Sequence ID of the interaction, and a reset of the Interaction Sequence Update ID.
 * Screenpops should not be performed if they are disabled: [[registerScreenpopControlChanged]]
 *
 * - See [[registerOnInteraction]]
 * @param interaction
 */
export function setInteraction(interaction: IInteraction): Promise<SearchRecords> {
  return sendRequestToFramework(OPERATIONS.INTERACTION, [interaction]).then((val: any) => SearchRecords.fromJSON(val));
}

/**
 * This registers the app to handle interaction events, which is triggered each time an interaction changes.
 *
 * - See [[setInteraction]]
 *
 * @param callback This will be called each time an interaction is added or has its state changed.
 * Apps that are integrated with a CRM should preform a screenpop when a new interaction occurs.
 */
export function registerOnInteraction(callback: (interaction: IInteraction) => Promise<SearchRecords>): Promise<void> {
  return sendRegisterRequestToFramework(fixInteractionDetails(callback), OPERATIONS.INTERACTION);
}

/**
 * This registers the app to handle interaction events. While similar to [[setInteraction]], this is meant to be used
 * by apps that do not need to return a value to the framework as a result of the interaction.
 * Rather, this is meant to be used by apps that need to perform some action or asynchronous processing of an interaction.
 * If the results of this processing is an update of the interaction data, then a call to [[updateInteraction]]
 * with the new data should be done. This emplyes a push model of interaction processing instead of awaiting a response.
 *
 * - See [[updateInteraction]]
 *
 * @param callback This will be called each time an interaction is added or has its state changed.
 */
export function registerProcessInteraction(callback: (interaction: IInteraction) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(fixInteractionDetails(callback), OPERATIONS.INTERACTION);
}

/**
 * Apps which are registered to process interactions should call this to update the interaction data by using
 * the [[registerProcessInteraction]] method, should call this method to inform the framework of the updated interaction.
 * If a update is not required, i.e., the processor did not process, or there is not change, then this method should not be called.
 * Calling this method results in a change to the Interaction Sequence Update ID of the interaction.
 *
 * - See [[registerProcessInteraction]]
 *
 * @param callback This will be called each time an interaction is added or has its state changed.
 */
export function updateInteraction(interaction: IInteraction): Promise<void> {
  return sendRequestToFramework(OPERATIONS.UPDATE_INTERACTION, [interaction]);
}

// This fixes the details in the interaction to actually be a RecordItem
function fixInteractionDetails(f: (interaction: IInteraction) => Promise<SearchRecords> | Promise<void>) {
  return (interaction: IInteraction) => {
    if (interaction.details !== undefined && interaction.details !== null) {
      interaction.details = RecordItem.fromJSON(interaction.details);
    }
    return f(interaction);
  };
}
