/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { IContextualContact } from '../AmcApi';
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * One app has to be in charge of managing how one app's presence will effect the other apps.
 * That app should use this to receive events when other app's change their presence.
 *
 * See:
 * - [[registerGetPresence]]
 * - [[onPresenceChanged]]
 * - [[logout]]
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 *
 * @param callback This is called when ever an app request a change in the user's presence.
 * If the given presence/reason is not valid this function should reject.
 * If it is valid then this function should inform the other apps using onPresenceChanged.
 */
export function registerSetPresence(
  callback: (presence: string, reason?: string, workmodeStartDateTime?: string, initiatingApp?: string) => Promise<void>
): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SET_PRESENCE);
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * One app has to be in charge of managing how one app's presence will effect the other apps.
 * That app should use this to register to handler other apps asking what the current presence is.
 *
 * See:
 * - [[registerSetPresence]]
 * - [[onPresenceChanged]]
 * - [[logout]]
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 *
 * @param callback This is called whenever another app wants to know the current presence of the user.
 */
export function registerGetPresence(callback: () => Promise<{ presence: string; reason?: string }>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.GET_PRESENCE);
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * One app has to be in charge of managing how one app's presence will effect the other apps.
 * That app should use this to inform other apps that the presence of the agent has changed.
 * It should be called each time the presence or reason is changed.
 *
 * See:
 * - [[registerSetPresence]]
 * - [[registerGetPresence]]
 * - [[logout]]
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 * @param presence
 * @param reason
 * @param presenceColor the color of the presence(green for ready, red for not ready, etc..). Any valid css color is fine(rgb, hex, etc..)
 * @param initiatingApp the name of the app that caused this presence change
 */
export function onPresenceChanged(presence: string, reason?: string, presenceColor?: string, initiatingApp?: string) {
  return sendRequestToFramework(OPERATIONS.ON_PRESENCE_CHANGED, [presence, reason, presenceColor, initiatingApp]);
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * One app has to be in charge of managing how one app's presence will effect the other apps.
 * That app should use this to inform initiate the agents logout.
 *
 * See:
 * - [[registerSetPresence]]
 * - [[registerGetPresence]]
 * - [[onPresenceChanged]]
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 * @param reason Reason the user was logged out
 */
export function logout(reason?: string): Promise<void> {
  return sendRequestToFramework(OPERATIONS.LOGOUT, [reason]);
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * Use this to set the presence(and reason) of the agent.
 * The app that manages presence will then sync this across all the apps.
 *
 * Note that normally the app the manages the presence will have a list of valid presences.
 * If you are integrating with something with it's own presence then you should have a map
 * in your apps config in DaVinci Creators Studio that maps between that presence and the valid presences.
 *
 * See:
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 * @param presence A valid presence
 * @param reason A string explaining why the presence was changed(ex: Break, Lunch, end of shift)
 * @param workmodeStartDateTime the UTC time when the workmode change was requested. It's used to keep track of workmode events
 */
export function setPresence(presence: string, reason?: string, presenceColor?: string, workmodeStartDateTime?: string): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SET_PRESENCE, [presence, reason, presenceColor, workmodeStartDateTime]).then((val) => {}); // make sure we return void
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * Use this to get the current presence and reason of the user
 *
 * Note that normally the app the manages the presence will have a list of valid presences.
 * If you are integrating with something with it's own presence then you should have a map
 * in your apps config in DaVinci Creators Studio that maps between that presence and the valid presences.
 *
 * See:
 * - [[setPresence]]
 * - [[registerOnPresenceChanged]]
 * - [[registerOnLogout]]
 */
export function getPresence(): Promise<IGetPresenceResult> {
  return sendRequestToFramework(OPERATIONS.GET_PRESENCE);
}

/**
 * What is returned by [[getPresence]]
 */
export interface IGetPresenceResult {
  presence: string;
  reason?: string;
}

/**
 * All DaVinci apps can have a presence(ready, not ready, etc..).
 * Which will then be synced across all apps.
 *
 * Use this to receive an event each time the presence changes.
 *
 * Note that normally the app the manages the presence will have a list of valid presences.
 * If you are integrating with something with it's own presence then you should have a map
 * in your apps config in DaVinci Creators Studio that maps between that presence and the valid presences.
 *
 * See:
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnLogout]]
 * @param callback Called whenever the presence or reason of the agent changes.
 */
export function registerOnPresenceChanged(callback: (presence: string, reason?: string, initiatingApp?: string) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.ON_PRESENCE_CHANGED);
}

/**
 * Use this to receive an event when the agent logs out.
 * This is useful if you have some other service that needs to be logged out at that time.
 *
 * See:
 * - [[setPresence]]
 * - [[getPresence]]
 * - [[registerOnPresenceChanged]]
 * @param callback Called when the user logs out. The registered app should logout of any services when it receives this event.
 */
export function registerOnLogout(callback: (reason?: string) => Promise<void>): Promise<void> {
  return sendRegisterRequestToFramework(callback, OPERATIONS.LOGOUT);
}

export function getLiveAgents(): Promise<IContextualContact[]> {
  return sendRequestToFramework(OPERATIONS.GET_LIVE_AGENTS);
}
