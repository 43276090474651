/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRegisterRequestToFramework, sendRequestToFramework } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { ISupportedChannel } from '../models/SupportedChannel';

/**
 *  Channel Applications on the DaVinci framework must report to the framework which channel types
 * are supported. Some DaVinci apps will disable or enable specific features depending on the channels that
 * are available.
 *
 * See:
 * - [[registerSetSupportedChannels]]
 * - [[ISupportedChannel]]
 *
 * @param channels Array of channels that will be supported by this app.
 */
export function setSupportedChannels(channels: ISupportedChannel[]): Promise<void> {
  return sendRequestToFramework(OPERATIONS.SUPPORTED_CHANNEL, [channels]);
}

/**
 * Register to receive events where apps inform you what channels they support
 * @param callback Called when another app calls [[setSupportedChannels]]
 */
export function registerSetSupportedChannels(callback: (originatingAppName: string, channels: ISupportedChannel[]) => Promise<void>) {
  return sendRegisterRequestToFramework(callback, OPERATIONS.SUPPORTED_CHANNEL);
}
