import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { LoggerService } from '../logger.service';
import { StorageService } from '../storage.service';
import { ISNCustomerContact } from '../Model/ServiceNowModel';
import { IScenarioDetails } from '../Model/IScenarioDetails';
@Component({
  selector: 'app-search-information-service-now',
  templateUrl: './search-information-service-now.component.html',
  styleUrls: ['./search-information-service-now.component.css']
})
export class SearchInformationServiceNowComponent implements OnChanges, OnInit {
  @Output() agentSelectedCallerInformation: EventEmitter<
    { scenarioDetails: IScenarioDetails, entityIndex: number, associateEntity: boolean }
  > = new EventEmitter();
  @Input() customerContactList: any[];
  @Input() displayList: string[];
  @Input() entityIcons: string[];
  @Input() scenarioDetails: IScenarioDetails;
  @Input() entitySize: number;
  properDisplayList: string[];
  isSearchInformationMaximized: boolean;
  singleSearchResult: string;
  imageLocation: string;
  lastCallerId: string;
  isMultimatch: boolean;
  isNoMatch: boolean;
  lastCallerSysId: any;
  singleMatchImg;
  multiMatchIcons: string[];
  shouldShowAllMultiMatchOptions: boolean;
  singleMatchData: any[];

  private _fileName = 'search-information-service-now.component.ts';

  constructor(
    private loggerService: LoggerService,
    protected storageService: StorageService
  ) {
    this.loggerService.logger.logDebug(
      'searchInformationComponent: Constructor start'
    );
    this.isSearchInformationMaximized = true;
    this.loggerService.logger.logDebug(
      'searchInformationComponent: Constructor complete'
    );
    this.shouldShowAllMultiMatchOptions = false;
    // this.multiMatchIcons = [];
    // this.singleMatchData = [];
  }

  ngOnInit(): void {

    const fnName = 'ngOnInit';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {


    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  ngOnChanges(changes: SimpleChanges): void {

    const fnName = 'ngOnChanges';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      switch (this.scenarioDetails.entities.length) {
        case 0:
          this.isNoMatch = true;
          this.isMultimatch = false;
          break;
        case 1:
          this.isNoMatch = false;
          this.isMultimatch = false;
          break;
        default:
          this.isNoMatch = false;
          this.isMultimatch = true;
          break;

      }

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }


  }


  setSingleMatchImage(field) {

    const fnName = 'setSingleMatchImage';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);


    try {
      if (field === 'customer_contact') {
        this.singleMatchImg = '/assets/images/Icon_Contact.png';
      } else if (field === 'customer_account') {
        this.singleMatchImg = '/assets/images/Icon_Account.png';
      } else {
        this.singleMatchImg = '/assets/images/Miscellaneous_Icon.png';
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }
  protected onAgentSelectedCallerInformation(idx: number) {

    const fnName = 'onAgentSelectedCallerInformation';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      this.agentSelectedCallerInformation.emit({ scenarioDetails: this.scenarioDetails, entityIndex: idx, associateEntity: true });

    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }

  protected collapseCallerInfoResults() {
    this.shouldShowAllMultiMatchOptions = false;
  }

  protected expandCallerInfoResults() {
    this.shouldShowAllMultiMatchOptions = true;
  }


  getCustomerFromSysId(id: any) {

    const fnName = 'getCustomerFromSysId';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      let contact: ISNCustomerContact = null;
      this.customerContactList.forEach(element => {
        if (element.sys_id === id) {
          contact = element;
        }
      });
      return contact;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  buildSingleMatchDetails() {

    const fnName = 'buildSingleMatchDetails';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      if (this.displayList.length > 0 && this.properDisplayList.length > 0) {
        for (let i = 1; i < this.displayList.length; i++) {
          if (this.customerContactList[0].hasOwnProperty(this.displayList[i]) && this.customerContactList[0][this.displayList[i]] !== '') {
            this.singleMatchData.push([this.properDisplayList[i], this.customerContactList[0][this.displayList[i]]]);
          }
        }
      }
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  private getSNInteractionById(id: string) {

    const fnName = 'getSNInteractionById';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const res = this.customerContactList.find(customerContact => {
        return customerContact.sys_id === id;
      });
      return res;
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }
  }
}
