import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ServicenowComponent } from './servicenow.component';
import { HomeServicenowComponent } from './home/home-servicenow.component';
import { CreateServicenowComponent } from './create/create-servicenow.component';
import { LoggerService } from './logger.service';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';
import { TokenService } from './token.service';
import { ServiceNowService } from './service-now.service';
import { SearchInformationServiceNowComponent } from './search-information-service-now/search-information-service-now.component';
import { ActivityComponent } from './activity/activity.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCommonModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ScenarioFilter } from './filter.pipe';
import { IOAuthTokenData } from './Model/IOauth2Model';
import { parsePostHashQuery } from './utils';
import { StorageServiceKeys } from './Model/StorageServiceKeys';


@NgModule({
  declarations: [
    ServicenowComponent,
    HomeServicenowComponent,
    CreateServicenowComponent,
    SearchInformationServiceNowComponent,
    ActivityComponent,
    ScenarioFilter
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatTabsModule,
    RouterModule.forRoot([
      { path: '', component: HomeServicenowComponent, pathMatch: 'full' }
    ]),
    NgbModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatCommonModule
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigurationService,
        loggerService: LoggerService,
        httpClient: HttpClient
      ) => async () => {

        // If the token data is present, it means the user has been redirected from agent.
        // In this case we don't want to initialize the logger and pull the configurationData as the new window cannot communicate with agent.
        const tokenData: IOAuthTokenData = parsePostHashQuery(window.location.href);

        if (tokenData && tokenData.code && tokenData.state) {
          // localStorage.setItem(StorageServiceKeys.snAuthorizationCode, tokenData.code);
          localStorage.removeItem(StorageServiceKeys.snAuthWindowOpened);
          const requestBody = {
            "Guid": tokenData.state,
            "Code": tokenData.code
          }
          // Send POST request to initialize with token data
          try {
            const response = await httpClient.post('/sendcode', requestBody).toPromise();
            // Handle response if needed
            return response;  // Ensure to return the response if necessary
          } catch (error) {
            console.error('Initialization POST request failed', error);
            return Promise.resolve(null);  // Return a resolved promise on error
          } finally {
            // Attempt to close the window if it was opened programmatically
            window.close();
          }
        } else {
          await configService.loadConfigurationData();
          await loggerService.initialize();
        }
      },
      deps: [ConfigurationService, LoggerService, HttpClient],
      multi: true
    },
    TokenService,
    LoggerService,
    StorageService,
    ServiceNowService
  ],
  bootstrap: [ServicenowComponent]
})
export class AppModule { }
