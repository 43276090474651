import { ILoggerConfiguration } from '../models/LoggerConfiguration';
import { AbstractLogger } from './AbstractLogger';
import { ERROR_CODE } from './ErrorCode';
import { LOG_LEVEL } from './LogLevel';
import { LOG_SOURCE } from './LogSource';

/**
 * Logger for AMC's DaVinci Apps. Logs are sent to browser console only.
 *
 * @export
 * @class ConsoleLogger
 * @extends {AbstractLogger}
 */
export class ConsoleLogger extends AbstractLogger {
  constructor(logSource: LOG_SOURCE | string, isDev: boolean = false, apiUrl: string = null) {
    super(logSource, isDev, apiUrl);

    // No interval required for pushing logs
    this.clearTimeOutForLogger();
  }

  setConfiguration(config: ILoggerConfiguration): void {
    this.config = config;
  }

  logLoop(message: string, errorCode: ERROR_CODE = ERROR_CODE.Other, localTime: Date = new Date()): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Loop) {
      console.groupCollapsed(`%c${this._logSource}:\n${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc');
      console.log(this.generateLogObj(LOG_LEVEL.Loop, errorCode, message, localTime));
    }
    console.groupEnd();
  }

  logTrace(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Trace) {
      console.groupCollapsed(`%c${this._logSource}:\n${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc');
      console.log(this.generateLogObj(LOG_LEVEL.Trace, errorCode, message, localTime));
    }
    console.groupEnd();
  }

  logDebug(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Debug) {
      console.groupCollapsed(`%c${this._logSource}:\n${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc');
      console.log(this.generateLogObj(LOG_LEVEL.Debug, errorCode, message, localTime));
    }
    console.groupEnd();
  }

  logInformation(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Information) {
      console.groupCollapsed(`%c${this._logSource}:\n%c${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc', 'color:#01d2fc');
      console.log(this.generateLogObj(LOG_LEVEL.Information, errorCode, message, localTime));
      console.groupEnd();
    }
  }

  logWarning(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Warning) {
      console.groupCollapsed(`%c${this._logSource}:\n%c${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc', 'color:#fff756');
      console.log(this.generateLogObj(LOG_LEVEL.Warning, errorCode, message, localTime));
      console.groupEnd();
    }
  }

  logError(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Error) {
      console.groupCollapsed(`%c${this._logSource}:\n%c${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc', 'color:#ff2121');
      console.log(this.generateLogObj(LOG_LEVEL.Error, errorCode, message, localTime));
      console.groupEnd();
    }
  }

  logCritical(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (this.config['Log Level'] <= LOG_LEVEL.Critical) {
      console.groupCollapsed(`%c${this._logSource}:\n%c${message}`.substring(0, this.config['Console Logger']['Max Length']), 'color:#01adbc', 'color:#ff2121');
      console.log(this.generateLogObj(LOG_LEVEL.Critical, errorCode, message, localTime));
      console.groupEnd();
    }
  }

  pushLogs(): void {
    return;
  }

  pushLogsAsync(): Promise<void> {
    return Promise.resolve();
  }
}
