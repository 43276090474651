import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISNCustomerContact, ISNInteraction } from './Model/ServiceNowModel';
import { LoggerService } from './logger.service';
import { IInteraction, CHANNEL_TYPES } from '@amc-technology/davinci-api';
import { findNode } from '@angular/compiler';
import { StorageServiceKeys } from './Model/StorageServiceKeys';

@Injectable()
export class ServiceNowService {
  instanceURL: string;

  private _fileName = "service-now.service.ts";
  constructor(
    private tokenService: TokenService,
    private loggerService: LoggerService,
    private http: HttpClient
  ) { }

  async createSnInteraction(interaction: IInteraction): Promise<ISNInteraction> {
    const fnName = 'createSnInteraction';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);
    try {
      const snInteractionType = this.getSnType(interaction.channelType);
      const response: any = await this.http
        .post(
          `${this.instanceURL}/api/now/table/interaction`,
          {
            state: 'new',
            type: snInteractionType
          } as ISNInteraction,
          {
            headers: this.getHttpHeader()
          }
        )
        .toPromise();
      return response.result as ISNInteraction;
    } catch (error) {
      if (error['error']['error']['message'] === 'User Not Authenticated') {
        localStorage.removeItem(StorageServiceKeys.snAccessToken);
        //TODO: should precent multiple refreshToken request
        await this.tokenService.getNewAccessTokenWithRefreshToken();
        return this.createSnInteraction(interaction);
      } else {
        this.loggerService.logError(this._fileName, fnName, error);
      }
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName)
    }
  }

  async patchSnInteraction(
    interactionId: string,
    interactionPatch: object
  ): Promise<ISNInteraction> {
    const fnName = 'patchSnInteraction';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      const response: any = await this.http
        .patch(
          `${this.instanceURL}/api/now/table/interaction/${interactionId}`,
          interactionPatch,
          {
            headers: this.getHttpHeader()
          }
        )
        .toPromise();
      return response.result as ISNInteraction;
    } catch (error) {
      if (error['error']['error']['message'] === 'User Not Authenticated') {
        localStorage.removeItem(StorageServiceKeys.snAccessToken);
        await this.tokenService.getNewAccessTokenWithRefreshToken();
        return this.patchSnInteraction(interactionId, interactionPatch);
      } else {
        this.loggerService.logError(this._fileName, fnName, error);
      }
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName)
    }
  }

  private getSnType(channelType: CHANNEL_TYPES): string {
    const fnName = 'getSnType';
    this.loggerService.logFnPerimeter(this._fileName, fnName, true);

    try {
      if (CHANNEL_TYPES.Telephony === channelType) {
        return 'phone';
      }
      if (CHANNEL_TYPES.Chat === channelType) {
        return 'chat';
      }
      //TODO: Is it ok to return this?
      return CHANNEL_TYPES[channelType];
    } catch (error) {
      this.loggerService.logError(this._fileName, fnName, error);
    } finally {
      this.loggerService.logFnPerimeter(this._fileName, fnName);
    }

  }

  async getEntity(table: any, key: any, value: any): Promise<any[]> {
    return new Promise<any[]>(async (resolve, reject) => {
      const fnName = 'getEntity';
      try {
        let response = await this.fetch_table(
          table,
          key,
          encodeURIComponent(value)
        );
        const entityResponse = response.result;
        if (entityResponse.length !== 0) {
          const result: any[] = entityResponse as any[];
          resolve(result);
        }
        resolve([]);
      } catch (error) {
        if (error['error']['error']['message'] === 'User Not Authenticated') {
          localStorage.removeItem(StorageServiceKeys.snAccessToken);
          await this.tokenService.getNewAccessTokenByOpeningNewWindow();
          return this.getEntity(table, key, value);
        } else {
          this.loggerService.logError(this._fileName, fnName, error);
          reject();
        }
      }
    });
  }

  async createNewQ(object: any, table: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const fnName = 'createNewQ';
      try {
        const response: any = await this.http
          .post(
            `${this.instanceURL}/api/now/table/${table}`,
            object,
            {
              headers: this.getHttpHeader()
            }
          )
          .toPromise();

        resolve(response.result);
      } catch (error) {
        if (error['error']['error']['message'] === 'User Not Authenticated') {
          localStorage.removeItem(StorageServiceKeys.snAccessToken);
          await this.tokenService.getNewAccessTokenWithRefreshToken();
          return this.createNewQ(object, table);
        } else {
          this.loggerService.logError(this._fileName, fnName, error);
          reject();
        }
      }
    });
  }

  async fetch_table(table: string, field: string, value: string) {
    return new Promise<any>(async (resolve, reject) => {
      const fnName = 'fetch_table';
      try {
        const response: any = await this.http
          .get(
            `${this.instanceURL}/api/now/table/${table}?${field}=${value}`,
            {
              headers: this.getHttpHeader()
            }
          )
          .toPromise();
        resolve(response);
      } catch (error) {
        if (error['error']['error']['message'] === 'User Not Authenticated') {
          localStorage.removeItem(StorageServiceKeys.snAccessToken);
          await this.tokenService.getNewAccessTokenWithRefreshToken();
          return this.fetch_table(table, field, value);
        } else {
          this.loggerService.logError(this._fileName, fnName, error);
          reject();
        }
      }
    });
  }

  private getHttpHeader() {
    let access_token = this.tokenService.getAccessToken();
    try {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`
      });
    } catch (error) {
    }
  }
}
