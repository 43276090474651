/**
 * @packageDocumentation
 * @module DaVinci_API
 */

/**
 * Details about the logged in user.
 * Returned by [[getUserDetails]].
 */
export interface IUserDetails {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  attributes: string;
  profiles: { profileName: string; profileid: string; userid: string }[];
  userType?: USER_TYPES;
}

/**
 * The type of user, can be agent or client.
 */
export enum USER_TYPES {
  Agent,
  Client
}
